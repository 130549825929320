import {
  Filter,
  Filter4,
  FilterCenterFocus,
  SearchOutlined,
} from "@mui/icons-material";
import {
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Checkbox,
  TextField,
  InputAdornment,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TablePagination,
  FormLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  fetchAllStartups,
  fetchStartups,
} from "../../../data/network/api/startupForm";
import {
  globalDispatch,
  globalSelector,
} from "../../../providers/GlobalProvider";
import { updateNavigation } from "../../../reducers/navigationReducer";
import { updateData } from "../../../reducers/startupform/formReducer";
import { updateStartupList } from "../../../reducers/startups/startupsList";
import { handleTopBarTitle } from "../../../utils/helperHandler";
import { Icons } from "../../../utils/mediaExports";
import { domainAndSectors } from "../../../utils/optionValues";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: "15rem",
    },
  },
};

const filterNames = {
  "femaleFounder": "Female Founders",
  "bigScheme": "BIG Scheme Startups",
  "ikp": "IKP Incubated Startups",
  "prayas": "Nidhi Prayas Scheme Startups",
}

const tableHeadingsList = [
  {
    id: "sl",
    title: "Sl No",
    sort: false,
    align: "center",
  },
  {
    id: "companyName",
    title: "Company Name",
    sort: false,
    align: "left",
  },
  {
    id: "founders",
    title: "Founders",
    sort: false,
    align: "left",
  },
  {
    id: "industry",
    title: "Industry",
    sort: false,
    align: "left",
  },
  {
    id: "location",
    title: "Location",
    sort: false,
    align: "left",
  }
];

const StartupsList = () => {
  const [industrySelected, setIndustrySelected] = useState([]);

  const startupStore = globalSelector((state) => state.startupList);
  const [counts, setCounts] = useState(null);
  const dispatch = globalDispatch();
  const controller = new AbortController();

  const [activeFilterBox, setActiveFilterBox] = useState(null);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIndustrySelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    getAllStartups();
  }, []);

  const getAllStartups = async () => {
    setActiveFilterBox(null);
    const response = await fetchAllStartups({}, dispatch, controller);
    if (response && response?.status == 200) {
      let startups = [];
      response?.msg?.companies?.map((company, i) => {
        startups.push({
          id: `${company?.companyName}_${i}`,
          _id: company?._id,
          name: `${company?.companyName}`,
          industry: String(company?.details?.domainAndSector).toUpperCase(),
          location: {
            city: company?.contact?.city,
            state:  company?.contact?.state,
          },
          founders: company?.details?.founders?.join(", ") || "-",
          sl: i + 1,
        });
      });
      dispatch(
        updateStartupList({
          value: [...startups],
        })
      );
      setCounts(response?.msg);
    }
  };

  const getStartups = async () => {
    const response = await fetchStartups(
      { filter: activeFilterBox },
      dispatch,
      controller
    );
    if (response && response?.status == 200) {
      let startups = [];
      response?.msg?.map((company, i) => {
        startups.push({
          id: `${company?.companyName}_${i}`,
          _id: company?._id,
          name: `${company?.companyName}`,
          industry: String(company?.details?.domainAndSector).toUpperCase(),
          founders: company?.details?.founders?.join(", ") || "-",
          location: {
            city: company?.contact?.city,
            state:  company?.contact?.state,
          },
          sl: i + 1,
        });
      });
      dispatch(
        updateStartupList({
          value: [...startups],
        })
      );
    }
  };

  useEffect(() => {
    if (!activeFilterBox) return;
    getStartups();
  }, [activeFilterBox]);

  return (
    <Container>
      <FilterContainer>
        <FilterBoxes>
          <div className="filterBox box1">
            <div className="row">
              <span className="numbers">
                {counts ? counts?.totalStartups : 0}
              </span>
              <span className="icon">{Icons.startup}</span>
            </div>
            <div className="row">
              <span className="numberDesc">Total Startups in the Platform</span>
              <span className="filterIcon" onClick={() => getAllStartups()}>
                {Icons.filter}
              </span>
            </div>
          </div>

          <div className="filterBox box2">
            <div className="row">
              <span className="numbers">
                {counts
                  ? counts?.femaleFounderCount + " / " + counts?.totalStartups
                  : "0 / 0"}
              </span>
              <span className="icon">{Icons.startup}</span>
            </div>
            <div className="row">
              <span className="numberDesc">
                Startups with Female Founders
              </span>
              <span
                className="filterIcon"
                onClick={() => setActiveFilterBox("femaleFounder")}
              >
                {Icons.filter}
              </span>
            </div>
          </div>

          <div className="filterBox box3">
            <div className="row">
              <span className="numbers">
                {counts
                  ? counts?.prayasStartups + " / " + counts?.totalStartups
                  : "0 / 0"}
              </span>
              <span className="icon">{Icons.startup}</span>
            </div>
            <div className="row">
              <span className="numberDesc">
                Startups with Nidhi Prayas Scheme
              </span>
              <span className="filterIcon"  onClick={() => setActiveFilterBox("prayas")}>{Icons.filter}</span>
            </div>
          </div>

          <div className="filterBox box4">
            <div className="row">
              <span className="numbers">
                {counts
                  ? counts?.bigStartups + " / " + counts?.totalStartups
                  : "0 / 0"}
              </span>
              <span className="icon">{Icons.startup}</span>
            </div>
            <div className="row">
              <span className="numberDesc">Startups with BIG Scheme</span>
              <span
                className="filterIcon"
                onClick={() => setActiveFilterBox("bigScheme")}
              >
                {Icons.filter}
              </span>
            </div>
          </div>

          <div className="filterBox box5">
            <div className="row">
              <span className="numbers">
                {counts
                  ? counts?.ikpStartups + " / " + counts?.totalStartups
                  : "0 / 0"}
              </span>
              <span className="icon">{Icons.startup}</span>
            </div>
            <div className="row">
              <span className="numberDesc">Startups associated with IKP</span>
              <span className="filterIcon" onClick={() => setActiveFilterBox("ikp")}>{Icons.filter}</span>
            </div>
          </div>
        </FilterBoxes>

        <div className="panels">
          <div className="left-panel panel">
            <FormLabel>Current Active filter: {activeFilterBox ? filterNames?.[activeFilterBox] : "" || "All Startups"}</FormLabel>
            {/* <div className="filter-item industry">
            <InputLabel className="input-label">Industry</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              input={<OutlinedInput size="small" />}
              value={industrySelected}
              style={{ width: "15rem" }}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {domainAndSectors.map((obj, i) => (
                <MenuItem key={i} value={obj.value}>
                  <Checkbox
                    checked={industrySelected.indexOf(obj.value) > -1}
                    size={"small"}
                  />
                  <ListItemText primary={obj.title} />
                </MenuItem>
              ))}
            </Select>
          </div> 

          <div className="filter-item program"></div> */}
          </div>

          <div className="right-panel panel">
            <div className="filter-item search">
              <InputLabel className="input-label">&nbsp;</InputLabel>
              <TextField
                size="small"
                placeholder="Search by Company name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <FormLabel  className="upcomingText">* Upcoming</FormLabel>
            </div>

            <div className="filter-item download">
              <InputLabel className="input-label">&nbsp;</InputLabel>
              <Button className="downloadBtn" variant="outlined">
                Download
              </Button>
              <FormLabel className="upcomingText">* Upcoming</FormLabel>
            </div>
          </div>
        </div>
      </FilterContainer>

      <TableWrapper>
        <TableBuilder
          selectedIndustry={industrySelected}
          startups={startupStore.list}
        />
      </TableWrapper>
    </Container>
  );
};

export default StartupsList;

const TableBuilder = ({ selectedIndustry, startups }) => {
  const [tableHeadings, setTableHeadings] = useState([...tableHeadingsList]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateSort = (index) => {
    let array = [...tableHeadings];
    array[index].sort = !array[index].sort;
    setTableHeadings(array);
  };

  return (
    <TableContainer className="table-container" component={Paper}>
      <Table>
        <TableHead>
          <TableRow className="table-head-row">
            {tableHeadings.map((tableHeading, i) => {
              return (
                <TableCell
                align={tableHeading.align} 
                  key={i}
                  className={"th-cell"}
                  onClick={() => updateSort(i)}
                >
                  <span className="text">{tableHeading.title}</span>
                  <span className="icon">
                    {tableHeading.sort
                      ? Icons.arrowDropup
                      : Icons.arrowDropdown}
                  </span>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {startups
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((startup, i) => {
              return <RowBuilder key={i} startup={startup} index={i} />;
            })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100, { label: "All", value: -1 }]}
              colSpan={tableHeadings.length}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              count={startups.length}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const RowBuilder = ({ startup, index }) => {
  const navigate = useNavigate();
  const dispatch = globalDispatch();

  const handleClick = () => {
    const link = `/startups/${startup?._id}`;
    navigate(link);
    handleTopBarTitle(dispatch, { title: startup?.name, link: link });
  };

  return (
    <TableRow className="table-data-row" onClick={handleClick}>
      <TableCell align="center" style={{width: "60px"}}>{startup.sl}</TableCell>
      <TableCell>{startup.name}</TableCell>
      <TableCell>{startup.founders}</TableCell>
      <TableCell>{startup.industry}</TableCell>
      <TableCell>{startup.location?.city ? startup.location.city + ", " + startup.location?.state : "-"}</TableCell>
    </TableRow>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;

  .panels {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .panel {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .filter-item {
      display: flex;
      flex-direction: column;

      .upcomingText {
        font-size: 0.8rem;
        padding: 0.1rem;
      }

      .MuiTextField-root {
        background: #fff;

        .MuiOutlinedInput-root {
          color: var(--text1);
        }

        .Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid var(--primary);
          }
        }

        .MuiOutlinedInput-notchedOutline {
          border: none;
        }

        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid var(--primary);
          }
        }
      }

      .input-label {
        font-size: 0.9rem;
        letter-spacing: 0.9px;
      }
    }

    .download {
      .downloadBtn {
        color: var(--primary) !important;
      }
    }
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  .table-container {
    table {
      thead {
        .table-head-row {
          .th-cell {
            color: var(--text1);
            cursor: pointer;
            transition: 0.2s;
            vertical-align: middle;

            .icon {
              font-size: 1.1rem;
              vertical-align: middle;
            }

            &:hover {
              color: gray;
            }
          }
        }
      }
      tbody {
        .table-data-row {
          cursor: pointer;
          transition: background 0.2s;

          &:hover {
            background: rgba(1, 210, 178, 0.2);
          }
        }
      }
    }
  }
`;

const FilterBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2rem;
  margin-bottom: 1rem;

  .filterBox {
    background: #fff;
    border-radius: 8px !important;
    box-shadow: 0 24px 64px rgb(0 0 0 / 4%);
    width: 250px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 135px;
    position: relative;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      position: relative;
      height: 100%;

      .numbers {
        font-size: 1.5rem;
        font-weight: bold;
        padding: 1rem 1.5rem;
      }

      .icon {
        font-size: 1.5rem;
        background: #e3fff0;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 100%;
        padding: 0 1rem;
        transition: 0.1s width;
        position: absolute;
        right: 0;
        top: 0;

        &:hover {
          width: 6rem;
        }
      }

      .numberDesc {
        padding: 0rem 1.5rem;
        margin-right: 1rem;
      }

      .filterIcon {
        font-size: 1.5rem;
        color: var(--text1);
        cursor: pointer;
        transition: 0.1s transform;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-right: 0.4rem;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .box1 {
    .row {
      .numbers,
      .icon {
        color: #2ed47a;
      }
    }
  }

  .box2 {
    .row {
      .numbers,
      .icon {
        color: #ff3a44;
      }

      .icon {
        background: #ffe9e9;
      }
    }
  }

  .box3 {
    .row {
      .numbers,
      .icon {
        color: #ffa500;
      }

      .icon {
        background: #fff4e0;
      }
    }
  }

  .box4 {
    .row {
      .numbers,
      .icon {
        color: #61758a;
      }

      .icon {
        background: #eeeeee;
      }
    }
  }

  .box5 {
    .row {
      .numbers,
      .icon {
        color: #00d1b1;
      }

      .icon {
        background: #cdf8f1;
      }
    }
  }
`;
