import { Icons } from "./mediaExports";

const paths = { 
  login: {
    title: "Login",
    path: "/auth/login",
  },
  signUp: {
    title: "Signup",
    path: "/auth/create-account",
  },

  home: {
    title: "Home",
    path: "/",
    id: "home",
  },
  startups: {
    title: "Startups",
    path: "/startups",
    id: "startups",
  },

  otherUsers: {
    title: "Other Users",
    path: "/otherUsers",
  },
  manageNotifications: {
    title: "Manage Notfications",
    path: "",
  },
  reports: {
    title: "Reports",
    path: "",
  },
  forum: {
    title: "Forum",
    path: "",
  },
  profile: {
    title: "Profile",
    path: "",
  },
  notifications: {
    title: "Notifications",
    path: "",
  },
  logout: {
    title: "Logout",
    path: "",
  },

  addStartup: {
    title: "Add Startup",
    link: "/startups/add",
  },
  viewStartup: {
    title: "Startup",
    link: "/startup/:id",
  },

  big: {
    title: "Biotechnology Ignition Grant",
    abbr: "BIG",
    link: "/schemes/big",
    id: "big",
  }
};

export const SidebarLinks = {
  main: {
    title: "",
    hide: false,
    links: {
      home: {
        title: paths.home.title,
        link: paths.home.path,
        icon: Icons.home,
      },
      startups: {
        title: paths.startups.title,
        link: paths.startups.path,
        icon: Icons.startup,
      },
    },
  },
  schemes: {
    title: "Schemes",
    hide: false,
    links: {
      big: {
        title: paths.big.abbr,
        link: paths.big.link,
        icon: Icons.startup,
      },
    },
  },
  more: {
    title: "More",
    hide: true,
    links: {
      // reports: {
      //   title: paths.reports.title,
      //   link: paths.reports.path,
      //   icon: Icons.report,
      // },
      // forum: {
      //   title: paths.forum.title,
      //   link: paths.forum.path,
      //   icon: Icons.forum,
      // },
      // profile: {
      //   title: paths.profile.title,
      //   link: paths.profile.path,
      //   hide: true,
      //   icon: Icons.profile,
      // },
      // notifications: {
      //   title: paths.notifications.title,
      //   link: paths.notifications.path,
      //   hide: true,
      //   icon: Icons.notificationList,
      // },
      logout: {
        title: paths.logout.title,
        link: paths.logout.path,
        hide: true,
        icon: Icons.logout,
      },
    },
  },
};

export default paths;
