import { RiHome2Line, RiLogoutCircleLine } from 'react-icons/ri';
import { BiRocket, BiMenu, BiPlus } from 'react-icons/bi';
import { IoMdNotificationsOutline, IoIosArrowForward } from 'react-icons/io';
import { FiPieChart } from 'react-icons/fi';
import { GoCommentDiscussion } from 'react-icons/go';
import { TbArrowsRight, TbArrowsLeft } from 'react-icons/tb';
import { CgProfile } from "react-icons/cg";
import { MdOutlineCircleNotifications, MdKeyboardArrowDown, MdArrowDropDown, MdArrowDropUp, MdDoubleArrow } from "react-icons/md";
import { RxCross2, RxCrossCircled } from 'react-icons/rx';
import { FaUsers } from 'react-icons/fa';
import { HiOutlineUserGroup } from 'react-icons/hi';
import {AiOutlineFilter} from 'react-icons/ai';
import Toggle from "../assets/icons/view-toggle.svg";

import MainLogo from "../assets/logo.svg";

import FlyingGif from "../assets/Flying_Transparent.gif";
import HourglassGif from "../assets/hourglass.gif";
import { Delete, DeleteOutline, Edit, Save, UploadFile } from '@mui/icons-material';

import LoginBg from "../assets/loginBg.png";
import Google from "../assets/google.png";
import Facebook from "../assets/facebook.png";
import Linkedin from "../assets/linkedin.png";

const Icons = {
    arrowShrinkLeft: <TbArrowsLeft />,
    arrowShrinkRight: <TbArrowsRight />,
    home: <RiHome2Line />,
    startup: <BiRocket />,
    notification: <IoMdNotificationsOutline />,
    report: <FiPieChart />,
    forum: <GoCommentDiscussion />,
    profile: <CgProfile />,
    notificationList: <MdOutlineCircleNotifications />,
    logout: <RiLogoutCircleLine />,
    hamburger: <BiMenu />,
    close: <RxCross2 />,
    keyboardArrowDown: <MdKeyboardArrowDown />,
    arrowDropdown: <MdArrowDropDown />,
    arrowDropup: <MdArrowDropUp />,
    plus: <BiPlus />,
    closeRound: <RxCrossCircled />,
    users: <FaUsers />,
    otherUsers: <HiOutlineUserGroup />,
    uploadFile: <UploadFile />,
    delete: <DeleteOutline />,
    schemeViewToggle: <img src={Toggle} />,
    arrowFormward: <IoIosArrowForward />,
    save: <Save />,
    edit: <Edit />,
    delete: <Delete />,
    doubleArrow: <MdDoubleArrow/>,
    filter: <AiOutlineFilter />,
};

const Logo = {
    main: MainLogo,
};

const Gif = {
    flying: FlyingGif,
    hourglass: HourglassGif,
};

const Image = {
    loginBg: LoginBg,
    google: Google,
    facebook: Facebook,
    linkedin: Linkedin,
};


export {Icons, Logo, Gif, Image};