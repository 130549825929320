import { createSlice } from "@reduxjs/toolkit";

export const StartupFormDataSlice = createSlice({
  name: "startup",
  initialState: {
    summary: {
      summary: {
        companyName: {
          error: "",
          value: "",
          active: true,
          mandatory: true,
        },
        aboutCompany: {
          error: "",
          value: "",
          active: true,
          mandatory: true,
        },
        hashtag: {
          error: "",
          value: [],
          active: true,
          mandatory: true,
        },
        maturityLevel: {
          error: "",
          value: "",
          active: true,
          mandatory: true,
        },
        fundsRaised: {
          error: "",
          value: "",
          active: true,
          mandatory: true,
        },
        revenueGenerated: {
          error: "",
          value: "",
          active: true,
          mandatory: true,
        },
        recognitionsAndAwards: {
          error: "",
          value: "",
          active: true,
          mandatory: true,
        },
        companyStatus: {
          error: "",
          value: "active",
          active: true,
          mandatory: true,
        },
        productGallery: {
          error: "",
          value: [],
          active: true,
          mandatory: false,
        },
      },
    },
    companyDetails: {
      companyDetails: {
        domainAndSector: {
          error: "",
          value: -1,
          mandatory: true,
          active: true,
        },
        gst: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        registrationDate: {
          error: "",
          value: new Date(),
          mandatory: true,
          active: true,
        },
        cin: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        tan: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        pan: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        indian: {
          error: "",
          value: "indian",
          mandatory: true,
          active: true,
        },
        hasParentCompany: {
          error: "",
          value: "no",
          mandatory: true,
          active: true,
        },
        parentCompanyName: {
          error: "",
          value: "",
          mandatory: false,
          active: false,
        },
        parentRegistrationDate: {
          error: "",
          value: new Date(),
          mandatory: false,
          active: false,
        },
        parentCin: {
          error: "",
          value: "",
          mandatory: false,
          active: false,
        },
        parentTan: {
          error: "",
          value: "",
          mandatory: false,
          active: false,
        },
        parentPan: {
          error: "",
          value: "",
          mandatory: false,
          active: false,
        },
      },
    },
    productShowcase: {
      productDetails: {
        array: [],
        productName: {
          error: "",
          value: "",
          active: true,
        },
        writeUp: {
          error: "",
          value: "",
          active: true,
        },
        workflow: {
          error: "",
          value: "",
          active: true,
        },
        technicalData: {
          error: "",
          value: "",
          active: true,
        },
        specifications: {
          error: "",
          value: "",
          active: true,
        },
        trl: {
          error: "",
          value: -1,
          active: true,
        },
        productGallery: {
          error: "",
          value: [],
          active: true,
        },
      },
      ipDetails: {
        array: [],
        applicant: {
          error: "",
          value: "",
          active: true,
        },
        collaborator: {
          error: "",
          value: "",
          active: true,
        },
        licensed: {
          error: "",
          value: "",
          active: true,
        },
        jointly: {
          error: "",
          value: "",
          active: true,
        },
      },
      patents: {
        array: [],
        patentStatus: {
          error: "",
          value: "",
          active: true,
        },
        patentNumber: {
          error: "",
          value: "",
          active: true,
        },
        patentTitle: {
          error: "",
          value: "",
          active: true,
        },
        productName: {
          error: "",
          value: "",
          active: true,
        },
        productDetails: {
          error: "",
          value: "",
          active: true,
        },
        jurisdiction: {
          error: "",
          value: "",
          active: true,
        },
        similarPatent: {
          error: "",
          value: "",
          active: true,
        },
      },
      copyrights: {
        array: [],
        name: {
          error: "",
          value: "",
          active: true,
        },
        registration: {
          error: "",
          value: new Date(),
          active: true,
        },
        expiry: {
          error: "",
          value: new Date(),
          active: true,
        },
        currentStatus: {
          error: "",
          value: "",
          active: true,
        },
        type: {
          error: "",
          value: "",
          active: true,
        },
        description: {
          error: "",
          value: "",
          active: true,
        },
      },
    },
    process: {
      legalDocuments: {
        memorandumOfAssociation: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        articleOfAssociation: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        gstCertification: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        shareholderAgreement: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        professionalTax: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        incorporationCertificate: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        shareholderPattern: {
          error: "",
          value: {
            indian: "0",
            foreign_nri: "0",
            foreign_individual: "0",
          },
          mandatory: true,
          active: true,
        },
      },
      certifications: {
        fssai: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        iso: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        ce: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        fda: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        dcgi: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        rcgm: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        nba: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
        pcba: {
          error: "",
          value: {},
          mandatory: true,
          active: true,
        },
      },
    },
    financials: {
      grants: {
        array: [],
        grantName: {
          error: "",
          value: "",
          active: true,
        },
        projectTitle: {
          error: "",
          value: "",
          active: true,
        },
        projectSummary: {
          error: "",
          value: "",
          active: true,
        },
        totalGrantAmount: {
          error: "",
          value: "",
          active: true,
        },
        fundingAgency: {
          error: "",
          value: "",
          active: true,
        },
        projectManagementAgency: {
          error: "",
          value: "",
          active: true,
        },
        presentProjectStatus: {
          error: "",
          value: "",
          active: true,
        },
        startDate: {
          error: "",
          value: new Date(),
          active: true,
        },
        completionDate: {
          error: "",
          value: new Date(),
          active: true,
        },
      },
      investments: {
        array: [],
        dateInvestmentRaised: {
          error: "",
          value: new Date().toString(),
          active: true,
        },
        investmentKind: {
          error: "",
          value: -1,
          active: true,
        },
        instrumentType: {
          error: "",
          value: "",
          active: true,
        },
        terms: {
          error: "",
          value: "",
          active: true,
        },
        valuation: {
          error: "",
          value: "",
          active: true,
        },
        participants: {
          error: "",
          value: "",
          active: true,
        },
        exits: {
          error: "",
          value: "",
          active: true,
        },
        series: {
          error: "",
          value: "",
          active: true,
        },
      },
      awards: {
        array: [],
        agency: {
          error: "",
          value: "",
          active: true,
        },
        prize: {
          error: "",
          value: "",
          active: true,
        },
        benefits: {
          error: "",
          value: "",
          active: true,
        },
      },
      auditedFinancialStatement: {
        array: [],
        year: {
          error: "",
          value: "",
          active: true,
        },
        annualReport: {
          error: "",
          value: "",
          active: true,
        },
        balanceSheet: {
          error: "",
          value: "",
          active: true,
        },
        revenue: {
          error: "",
          value: "",
          active: true,
        },
        books: {
          error: "",
          value: "",
          active: true,
        },
      },
    },
    people: {
      people: {
        array: [],
        name: {
          error: "",
          value: "",
          active: true,
        },
        designation: {
          error: "",
          value: "",
          active: true,
        },
        gender: {
          error: "",
          value: -1,
          active: true,
        },
        educationQualification: {
          error: "",
          value: -1,
          active: true,
        },
        workExperience: {
          error: "",
          value: "",
          active: true,
        },
        emailId: {
          error: "",
          value: "",
          active: true,
        },
        phone: {
          error: "",
          value: "",
          active: true,
        },
        pan: {
          error: "",
          value: "",
          active: true,
        },
        aadhar: {
          error: "",
          value: "",
          active: true,
        },
        din: {
          error: "",
          value: "",
          active: true,
        },
        shareholding: {
          error: "",
          value: "",
          active: true,
        },
        esop: {
          error: "",
          value: "",
          active: true,
        },
        profitShare: {
          error: "",
          value: "",
          active: true,
        },
        org: {
          error: "",
          value: "",
          active: true,
        },
        keyContacts: {
          error: "",
          value: "",
          active: true,
        },
      },
    },
    association: {
      mentors: {
        array: [],
        _id: {
          mandatory: true,
          active: true,
          value: null,
          error: "",
        },
        name: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
          options: [],
        },
        sector: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        mentoredCompanies: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        specialization: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        feesCharged: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        advisoryShares: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
      },
      investors: {
        array: [],
        _id: {
          mandatory: true,
          active: true,
          value: null,
          error: "",
        },
        name: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
          options: [],
        },
        investedSectors: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        investedCompanies: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        proficiency: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        ticketSize: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        investmentInCurrentCompany: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
      },
      incubators: {
        array: [],
        _id: {
          mandatory: true,
          active: true,
          value: null,
          error: "",
        },
        name: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
          options: [],
        },
        address: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        since: {
          error: "",
          value: new Date(),
          mandatory: true,
          active: true,
        },
        mou: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        focusAreas: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        saveBtn: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
      },
      vendors: {
        array: [],
        _id: {
          mandatory: true,
          active: true,
          value: null,
          error: "",
        },
        name: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
          options: [],
        },
        natureOfWork: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
      },
    },
    jobOpenings: {
      jobPostings: {
        array: [],
        jobTitle: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        jobDescription: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        link: {
          error: "",
          value: "",
          mandatory: false,
          active: true,
        },
      },
    },
    planOfAction: {
      planOfAction: {
        array: [],
        duration: {
          error: "",
          value: -1,
          mandatory: true,
          active: true,
        },
        outcome: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        future: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        businessPlan: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        kpi: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        okr: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        saveBtn: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
      },
    },
    contact: {
      contact: {
        phoneNumber: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        emails: {
          error: "",
          value: [],
          mandatory: true,
          active: true,
        },
        website: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        officeAddress: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        city: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        state: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        country: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        twitter: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        pincode: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        linkedin: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
        github: {
          error: "",
          value: "",
          mandatory: true,
          active: true,
        },
      },
    },

    errorTabs: []
  },
  reducers: {
    updateData: (state, actions) => {
      if (actions.payload["type"] == "array") {
        state[actions.payload["category"]][
          actions.payload["subCategory"]
        ].array = actions.payload.value;
        return;
      }
      if (actions.payload["type"] == "multipleText") {
        console.log(actions.payload);
        state[actions.payload["category"]][actions.payload["subCategory"]][
          actions.payload["field"]
        ].value[actions.payload["id"]] = actions.payload.value;
        return;
      }
      if (actions.payload["type"] == "both") {
        state[actions.payload["category"]][actions.payload["subCategory"]][actions.payload["id"]].error = actions.payload.error;
        state[actions.payload["category"]][actions.payload["subCategory"]][actions.payload["id"]].value = actions.payload.value;
        return;
      }
      if (actions.payload["type"] == "errorTab") {
        state.errorTabs = actions.payload.errorTabs;
        return;
      }
      console.log(actions.payload)
      state[actions.payload["category"]][actions.payload["subCategory"]][
        actions.payload["id"]
      ][actions.payload["type"]] = actions.payload.value;
    },
  },
});

export const { updateData } = StartupFormDataSlice.actions;

export default StartupFormDataSlice.reducer;
