import { SnackbarProvider } from "notistack";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../components/navbars/Sidebar";
import TopBar from "../components/navbars/TopBar";

const Dashboard = () => {
  const [topbarDropdownActive, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown(!topbarDropdownActive);
  };

  return (
    <Container>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Sidebar />

        <ContentContainer id="content-container">
          {topbarDropdownActive ? (
            <div className="overlay" onClick={handleDropdown}></div>
          ) : null}

          <TopBar
            topbarDropdownActive={topbarDropdownActive}
            handleDropdown={handleDropdown}
          />

          <Out>
            <Outlet />
          </Out>
        </ContentContainer>
      </SnackbarProvider>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  flex: 1;
  height: 100vh;
  overflow: hidden;

  .overlay {
    position: absolute;
    top: var(--navHeight);
    left: 0;
    right: 0;
    bottom: 0;
    background: hsla(0, 0%, 100%, 0.7);
    z-index: 3;
  }

  @media only screen and (max-width: 1000px) {
    margin-top: var(--navHeight);
  }
`;

const Out = styled.div`
  min-height: calc(100vh - var(--navHeight));
  background: #f8f8f8;
  overflow: auto;
  padding: 1rem;
`;
