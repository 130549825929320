import {
  domainAndSectors,
  educationQualification,
  gender,
  investmentsKind,
  planDuration,
  trlLevels,
} from "../../../utils/optionValues";

const summaryJson = {
  summary: {
    title: "Summary",
    fields: {
      companyName: {
        title: "Company Name",
        inputType: "text",
        default: "",
      },
      companyStatus: {
        title: "Company Status",
        inputType: "radio",
        options: [
          {
            title: "Active",
            value: "active",
          },
          {
            title: "Inactive",
            value: "inactive",
          },
        ],
        default: "active",
      },
      aboutCompany: {
        title: "About Company",
        inputType: "text",
        multiline: true,
        gridColumn: "1/-1",
        rows: 4,
        default: "",
      },
      hashtag: {
        title: "Hashtag",
        inputType: "chipInput",
        default: [],
      },
      maturityLevel: {
        title: "Maturity Level",
        inputType: "text",
        default: "",
      },
      fundsRaised: {
        title: "Funds Raised (₹)",
        inputType: "text",
        default: "",
      },
      revenueGenerated: {
        title: "Revenue Generated (₹)",
        inputType: "text",
        default: "",
      },
      recognitionsAndAwards: {
        title: "Recognitions and Awards",
        inputType: "text",
        multiline: true,
        gridColumn: "1/-1",
        rows: 4,
        default: "",
      },
      productGallery: {
        title: "Product Gallery",
        inputType: "product-gallery",
        default: [],
      },
    },
  },
};

const companyDetailsJson = {
  companyDetails: {
    title: "Company Details",
    fields: {
      domainAndSector: {
        title: "Domain and Sector",
        inputType: "select",
        options: domainAndSectors,
        default: -1,
      },
      gst: {
        title: "GST Number",
        inputType: "text",
        default: "",
      },
      registrationDate: {
        title: "Registration Date",
        inputType: "date",
        default: "",
      },
      cin: {
        title: "CIN Number",
        inputType: "text",
        default: "",
      },
      tan: {
        title: "TAN Number",
        inputType: "text",
        default: "",
      },
      pan: {
        title: "PAN Number",
        inputType: "text",
        default: "",
      },
      indian: {
        title: "Company Is: ",
        inputType: "radio",
        gridColumn: "1/-1",
        options: [
          {
            title: "Indian",
            value: "indian",
          },
          {
            title: "Non-Indian",
            value: "non-indian",
          },
        ],
        default: "indian",
      },
      hasParentCompany: {
        title: "Is there any Parent Company ?",
        inputType: "radio",
        gridColumn: "1/-1",
        options: [
          {
            title: "Yes",
            value: "yes",
          },
          {
            title: "No",
            value: "no",
          },
        ],
        default: "no",
      },
      parentCompanyName: {
        title: "Company Name",
        inputType: "text",
        default: "",
      },
      parentRegistrationDate: {
        title: "Registration Date",
        inputType: "date",
        default: "",
      },
      parentCin: {
        title: "CIN Number",
        inputType: "text",
        default: "",
      },
      parentTan: {
        title: "TAN Number",
        inputType: "text",
        default: "",
      },
      parentPan: {
        title: "PAN Number",
        inputType: "text",
        default: "",
      },
    },
  },
};

const productShowcase = {
  productDetails: {
    hasTable: true,
    title: "Products Details",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      productName: {
        inputType: "text",
        title: "Product Name",
        mandatory: true,
        default: "",
      },
      writeUp: {
        inputType: "text",
        title: "About Product",
        mandatory: false,
        rows: 4,
        multiline: true,
        gridColumn: "1/-1",
        default: "",
      },
      workflow: {
        inputType: "text",
        title: "Product Workflow",
        mandatory: false,
        rows: 4,
        multiline: true,
        default: "",
      },
      technicalData: {
        inputType: "text",
        title: "Technical Data",
        mandatory: false,
        rows: 4,
        multiline: true,
        default: "",
      },
      specifications: {
        inputType: "text",
        title: "Specifications",
        mandatory: false,
        rows: 4,
        multiline: true,
        default: "",
      },
      trl: {
        inputType: "select",
        title: "TRL Level",
        mandatory: false,
        options: trlLevels,
        default: -1,
      },
      productGallery: {
        inputType: "product-gallery",
        title: "Product Image/Video",
        mandatory: false,
        default: [],
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
  ipDetails: {
    hasTable: true,
    title: "IP Details",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      applicant: {
        inputType: "text",
        title: "Applicant",
        mandatory: true,
        default: "",
      },
      collaborator: {
        inputType: "text",
        title: "Collaborator",
        mandatory: true,
        default: "",
      },
      licensed: {
        inputType: "text",
        title: "Licensed",
        mandatory: true,
        default: "",
      },
      jointly: {
        inputType: "text",
        title: "Jointly by Company & Collaborator",
        mandatory: true,
        default: "",
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
  patents: {
    hasTable: true,
    title: "Patents",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      patentStatus: {
        inputType: "text",
        title: "Patent Status",
        mandatory: true,
        default: "",
      },
      patentNumber: {
        inputType: "text",
        title: "Patent Number",
        mandatory: true,
        default: "",
      },
      patentTitle: {
        inputType: "text",
        title: "Patent Title",
        mandatory: true,
        gridColumn: "1/-1",
        default: "",
      },
      productName: {
        inputType: "text",
        title: "Product Name",
        mandatory: true,
        default: "",
      },
      jurisdiction: {
        inputType: "text",
        title: "Country of Origin/Jurisdiction",
        mandatory: true,
        default: "",
      },
      productDetails: {
        inputType: "text",
        title: "Product Details",
        mandatory: true,
        gridColumn: "1/-1",
        multiline: true,
        rows: 3,
        default: "",
      },
      similarPatent: {
        inputType: "text",
        title: "Similar Patent / Possible overlapping",
        mandatory: false,
        multiline: true,
        rows: 3,
        gridColumn: "1/-1",
        default: "",
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
  copyrights: {
    hasTable: true,
    title: "Copyrights",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      name: {
        inputType: "text",
        title: "Copyright Name",
        mandatory: true,
        gridColumn: "1/-1",
        default: "",
      },
      registration: {
        inputType: "date",
        title: "Registration Date",
        mandatory: true,
        default: "",
      },
      expiry: {
        inputType: "date",
        title: "Expiry Date",
        mandatory: true,
        default: "",
      },
      currentStatus: {
        inputType: "text",
        title: "Current Status",
        mandatory: true,
        default: "",
      },
      type: {
        inputType: "text",
        title: "Type",
        mandatory: true,
        default: "",
      },
      description: {
        inputType: "text",
        title: "Description",
        mandatory: false,
        multiline: true,
        rows: 3,
        gridColumn: "1/-1",
        default: "",
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
  trademark: {
    title: "Trademark",
    fields: {},
  },
  licensing: {
    title: "Licensing",
    fields: {},
  },
  royalty: {
    title: "Royalty",
    fields: {},
  },
};

const process = {
  legalDocuments: {
    title: "Company Legal Documents",
    fields: {
      memorandumOfAssociation: {
        title: "Memorandum of Association",
        inputType: "file",
        default: {},
      },
      articleOfAssociation: {
        title: "Article of Association",
        inputType: "file",
        default: {},
      },
      gstCertification: {
        title: "GST Certification",
        inputType: "file",
        default: {},
      },
      shareholderAgreement: {
        title: "Shareholder Agreement",
        inputType: "file",
        default: {},
      },
      professionalTax: {
        title: "Professional Tax",
        inputType: "file",
        default: {},
      },
      incorporationCertificate: {
        title: "Incorporation Date",
        inputType: "file",
        default: {},
      },
      shareholderPattern: {
        title: "Shareholder Pattern",
        inputType: "multipleText",
        options: [
          {
            id: "indian",
            title: "Indian",
            default: "",
          },
          {
            id: "foreign_nri",
            title: "Foreign NRI",
            default: "",
          },
          {
            id: "foreign_individual",
            title: "Foreign Individual",
            default: "",
          },
        ],
        default: "",
      },
    },
  },
  certifications: {
    title: "Certifications",
    fields: {
      fssai: {
        title: "FSSAI",
        inputType: "file",
        default: {},
      },
      iso: {
        title: "ISO",
        inputType: "file",
        default: {},
      },
      ce: {
        title: "CE",
        inputType: "file",
        default: {},
      },
      fda: {
        title: "FDA",
        inputType: "file",
        default: {},
      },
      dcgi: {
        title: "DCGI Approval",
        inputType: "file",
        default: {},
      },
      rcgm: {
        title: "RCGM & GEAC Approval",
        inputType: "file",
        default: {},
      },
      nba: {
        title: "National Biodiversity Approval",
        inputType: "file",
        default: {},
      },
      pcba: {
        title: "Pollution Control Board Approval",
        inputType: "file",
        default: {},
      },
    },
  },
};

const financials = {
  grants: {
    hasTable: true,
    title: "Grants",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      grantName: {
        inputType: "text",
        title: "Grant Name",
        mandatory: true,
        gridColumn: "1/-1",
        default: "",
      },
      projectTitle: {
        inputType: "text",
        title: "Project Title",
        mandatory: true,
        gridColumn: "1/-1",
        default: "",
      },
      projectSummary: {
        inputType: "text",
        title: "Project Summary",
        mandatory: true,
        gridColumn: "1/-1",
        multiline: true,
        rows: 4,
        default: "",
      },
      totalGrantAmount: {
        inputType: "text",
        title: "Total Grant Amount",
        mandatory: true,
        default: "",
      },
      fundingAgency: {
        inputType: "text",
        title: "Funding Agency",
        mandatory: true,
        default: "",
      },
      projectManagementAgency: {
        inputType: "text",
        title: "Project Management Agency",
        mandatory: false,
        default: "",
      },
      presentProjectStatus: {
        inputType: "text",
        title: "Present Project Status",
        mandatory: true,
        default: "",
      },
      startDate: {
        inputType: "date",
        title: "Start Date",
        mandatory: true,
        default: "",
      },
      completionDate: {
        inputType: "date",
        title: "Due date of Completion",
        mandatory: true,
        default: "",
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
  investments: {
    hasTable: true,
    title: "Investments",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      dateInvestmentRaised: {
        inputType: "date",
        title: "Investment Raised Date",
        mandatory: true,
        default: "",
      },
      investmentKind: {
        inputType: "select",
        title: "Kind of Investment",
        mandatory: true,
        options: investmentsKind,
        default: -1,
      },
      instrumentType: {
        inputType: "text",
        title: "Instrument Type",
        mandatory: true,
        default: "",
      },
      terms: {
        inputType: "text",
        title: "Terms",
        multiline: true,
        rows: 4,
        mandatory: false,
        gridColumn: "1/-1",
        default: "",
      },
      valuation: {
        inputType: "text",
        title: "Valuation",
        mandatory: false,
        default: "",
      },
      participants: {
        inputType: "text",
        title: "Participants",
        mandatory: true,
        multiline: true,
        default: "",
      },
      exits: {
        inputType: "text",
        title: "Exits",
        mandatory: true,
        default: "",
      },
      series: {
        inputType: "text",
        title: "Series",
        mandatory: true,
        default: "",
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
  awards: {
    hasTable: true,
    title: "Awards/Recognition",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      agency: {
        title: "Agency",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      prize: {
        title: "Prize Money",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      benefits: {
        title: "Benefits",
        inputType: "text",
        mandatory: true,
        multiline: true,
        rows: 3,
        gridColumn: "1/-1",
        default: "",
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
        default: "",
      },
    },
  },
  auditedFinancialStatement: {
    hasTable: true,
    title: "Audited Financial Statement",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      year: {
        title: "Year",
        inputType: "text",
        mandatory: true,
        gridColumn: "1/-1",
        default: "",
      },
      annualReport: {
        inputType: "file",
        title: "Annual Report",
        mandatory: true,
        default: {},
      },
      balanceSheet: {
        inputType: "file",
        title: "Balance Sheet",
        mandatory: true,
        default: {},
      },
      revenue: {
        inputType: "file",
        title: "Revenue/PnL",
        mandatory: true,
        default: {},
      },
      books: {
        inputType: "file",
        title: "Books and Billables",
        mandatory: true,
        default: {},
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
};

const people = {
  people: {
    hasTable: true,
    title: "People",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      name: {
        inputType: "text",
        mandatory: true,
        title: "Name",
        default: "",
      },
      designation: {
        title: "Designation",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      gender: {
        title: "Gender",
        inputType: "select",
        mandatory: true,
        options: gender,
        default: -1,
      },
      educationQualification: {
        title: "Educational Qualification",
        inputType: "select",
        mandatory: true,
        options: educationQualification,
        default: -1,
      },
      workExperience: {
        title: "Work Experience",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      emailId: {
        title: "Email Id",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      phone: {
        title: "Phone Number",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      pan: {
        title: "PAN",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      aadhar: {
        title: "Aadhar Number",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      din: {
        title: "DIN Number",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      shareholding: {
        title: "% Shareholding",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      esop: {
        title: "ESOP",
        inputType: "text",
        mandatory: false,
        default: "",
      },
      profitShare: {
        title: "Profit Share",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      org: {
        title: "Org Structure/Directory",
        inputType: "text",
        mandatory: false,
        default: "",
      },
      keyContacts: {
        title: "Key Contacts",
        inputType: "text",
        mandatory: false,
        rows: 3,
        multiline: true,
        default: "",
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
};

const association = {
  mentors: {
    hasTable: true,
    title: "Mentors",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      _id: {
        default: null,
      },
      name: {
        title: "Mentor Name",
        inputType: "searchAutocomplete",
        mandatory: true,
        default: "",
      },
      sector: {
        title: "Domain/Sector",
        inputType: "text",
        mandatory: true,
        default: [],
      },
      mentoredCompanies: {
        title: "Mentored Companies",
        inputType: "text",
        mandatory: true,
        default: [],
      },
      specialization: {
        title: "Specialization",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      feesCharged: {
        title: "Fees Charged",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      advisoryShares: {
        title: "Advisory Shares",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
        default: null,
      },
    },
  },
  investors: {
    hasTable: true,
    title: "Investors",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      _id: {
        default: null,
      },
      name: {
        title: "Investor Name",
        inputType: "searchAutocomplete",
        mandatory: true,
        default: "",
      },
      investedSectors: {
        title: "Invested Sectors",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      investedCompanies: {
        title: "Invested Companies",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      proficiency: {
        title: "Proficiency",
        inputType: "text",
        mandatory: false,
        default: "",
      },
      ticketSize: {
        title: "Ticket Size",
        inputType: "text",
        mandatory: false,
        default: "",
      },
      investmentInCurrentCompany: {
        title: "Investment in Current Company",
        inputType: "text",
        mandatory: false,
        default: "",
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
  incubators: {
    hasTable: true,
    title: "Incubators/Accelators",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      _id: {
        default: null,
      },
      name: {
        title: "Incubator Name",
        inputType: "searchAutocomplete",
        mandatory: true,
        default: "",
      },
      address: {
        title: "Incubator Address",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      since: {
        title: "Incubator Since",
        inputType: "date",
        mandatory: true,
        default: "",
      },
      focusAreas: {
        title: "Focus Areas",
        inputType: "text",
        mandatory: false,
        default: "",
      },
      mou: {
        title: "Mou",
        inputType: "file",
        mandatory: false,
        default: {},
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
  vendors: {
    hasTable: true,
    title: "Vendors",
    fields: {
      viewer: {
        inputType: "viewer",
      },
      _id: {
        default: null,
      },
      name: {
        title: "Vendor Name",
        inputType: "searchAutocomplete",
        mandatory: true,
        default: "",
      },
      natureOfWork: {
        title: "Nature of Work",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
};

const jobOpenings = {
  jobPostings: {
    title: "Job Postings",
    hasTable: true,
    fields: {
      viewer: {
        inputType: "viewer",
      },
      jobTitle: {
        title: "Job Title",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      jobDescription: {
        title: "Job Description",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      link: {
        title: "Link",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
};

const planOfAction = {
  planOfAction: {
    title: "Plan of Action",
    hasTable: true,
    fields: {
      viewer: {
        inputType: "viewer",
      },
      duration: {
        title: "Duration",
        inputType: "select",
        mandatory: true,
        options: planDuration,
        default: -1,
      },
      outcome: {
        title: "Outcome",
        inputType: "text",
        mandatory: true,
        multiline: true,
        gridColumn: "1/-1",
        rows: 2,
        default: "",
      },
      future: {
        title: "Future Plans of commercialization",
        inputType: "text",
        mandatory: true,
        multiline: true,
        rows: 2,
        default: "",
      },
      businessPlan: {
        title: "Business Plan",
        inputType: "text",
        mandatory: true,
        multiline: true,
        rows: 2,
        default: "",
      },
      kpi: {
        title: "KPI",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      okr: {
        title: "OKR",
        inputType: "text",
        mandatory: true,
        default: "",
      },
      saveBtn: {
        inputType: "button",
        title: "Add",
        mandatory: false,
        gridColumn: "1/-1",
      },
    },
  },
};

const contact = {
  contact: {
    title: "Contact",
    fields: {
      phoneNumber: {
        title: "Phone Number",
        inputType: "text",
        default: "",
      },
      emails: {
        title: "Email IDs",
        inputType: "chipInput",
        default: [],
      },
      website: {
        title: "Website",
        inputType: "text",
        default: "",
      },
      officeAddress: {
        title: "Office Address",
        inputType: "text",
        multiline: true,
        gridColumn: "1/-1",
        rows: 2,
        default: "",
      },
      city: {
        title: "City",
        inputType: "text",
        default: "",
      },
      state: {
        title: "state",
        inputType: "text",
        default: "",
      },
      country: {
        title: "Country",
        inputType: "text",
        default: "",
      },
      pincode: {
        title: "Pincode",
        inputType: "text",
        default: "",
      },
      twitter: {
        title: "Twitter",
        inputType: "text",
        default: "",
      },
      linkedin: {
        title: "LinkedIn",
        inputType: "text",
        default: "",
      },
      github: {
        title: "Github",
        inputType: "text",
        default: "",
      },
    },
  },
};

const formSubmit = {
  submit: {
    title: "Submit",
    fields: {
      formSubmitBtn: {
        inputType: "submit",
      },
    },
  },
};

export const formJson = {
  summary: { name: "Summary", tabs: { ...summaryJson } },
  companyDetails: { name: "Company Details", tabs: { ...companyDetailsJson } },
  productShowcase: {
    name: "Product Showcase",
    hasTabs: true,
    tabs: { ...productShowcase },
  },
  process: {
    name: "Process",
    hasTabs: true,
    tabs: { ...process },
  },
  people: {
    name: "People",
    tabs: { ...people },
  },
  financials: {
    name: "Financials",
    hasTabs: true,
    tabs: { ...financials },
  },
  association: {
    name: "Associations",
    hasTabs: true,
    tabs: { ...association },
  },
  jobOpenings: {
    name: "Job Openings",
    tabs: { ...jobOpenings },
  },
  planOfAction: {
    name: "Plan of Action",
    tabs: { ...planOfAction },
  },
  contact: {
    name: "Contact",
    tabs: { ...contact },
  },
  formSubmit: {
    name: "Submit",
    tabs: { ...formSubmit },
  },
};
