import {
  Alert,
  Button,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { gapi, loadAuth2 } from "gapi-script";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getUser, signInUser } from "../../data/network/api/user";
import { updateAuth } from "../../reducers/auth/authReducer";
import { Validator } from "../../utils/helperHandler";
import paths from "../../utils/links";
import { Image, Logo } from "../../utils/mediaExports";
import LoadingScreen from "../../components/customComponents/LoadingScreen";
import CustomLoader from "../../components/customComponents/CustomLoader";
import { globalDispatch } from "../../providers/GlobalProvider";

const initialState = {
  email: "",
  password: "",
};

//try login using token if present
export const autoLoginAttempt = async (dispatch, navigate) => {
  const token = localStorage.getItem("access-token");
  if (!token) return;

  const response = await getUser(token, dispatch);
  if (response.status == 200) {
    dispatch(
      updateAuth({
        isAuthenticated: true,
      })
    );
    navigate(paths.home.path);
  }
};

const LoginPage = () => {
  const [user, setUser] = useState(null);
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const navigate = useNavigate();
  const [data, setData] = useState({ ...initialState });
  const [error, setError] = useState({ ...initialState });
  const [serverResponse, setServerResponse] = useState(null);

  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const updateFields = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    sessionStorage.removeItem("active-link");
    setup();
  }, []);

  const setup = async () => {
    setPageLoading(true);
    await autoLoginAttempt(dispatch, navigate);
    setPageLoading(false);
    const setAuth2 = async () => {
      const auth2 = await loadAuth2(
        gapi,
        "1041455530445-4kc2ofannmlg7mqms58qgmumvlee9fmc.apps.googleusercontent.com",
        ""
      );

      attachSignin(document.getElementById("googleSignIn"), auth2);
    };
    setAuth2();
  };

  const attachSignin = (element, auth2) => {
    auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        setUser(googleUser);
      },
      (error) => {
        console.log(JSON.stringify(error));
      }
    );
  };

  const handleSignIn = async () => {
    let error = { ...initialState };
    setServerResponse(null);
    let isValid = true;
    if (data.email == "") {
      isValid = false;
      error.email = "Email is required!";
    }
    if (data.password == "") {
      isValid = false;
      error.password = "Password is required!";
    }
    if (data.email != "" && !Validator.validateEmail(data.email)) {
      isValid = false;
      error.email = "Email is invalid!";
    }
    setError(error);
    if (!isValid) return;
    setLoading(true);
    const response = await signInUser(data, controller);
    console.log(response);
    if (response.status != 200) {
      setServerResponse({
        type: "error",
        msg: response?.msg || "Something went wrong!",
      });
    } else {
      const token = response.msg;
      localStorage.setItem("access-token", token);
      navigate(paths.home.path);
    }
    setLoading(false);
  };

  if (pageLoading) {
    return <LoadingScreen />;
  }

  return (
    <AuthContainer>
      <AuthPanel className="leftPanel">
        <img className="loginBg" src={Image.loginBg} alt="LoginBg" />
        <div className="content"></div>
      </AuthPanel>

      <AuthPanel
        className="rightPanel"
        style={loading ? { pointerEvents: "none" } : null}
      >
        <div className="content">
          <div className="topBar">
            <div className="links">
              <span>About</span>
              <span>Blog</span>
              <span>Terms</span>
              <span>Privacy Policy</span>
              <span>Contact Us</span>
            </div>
          </div>

          <div className="loginForm">
            <div className="title">Sign In To Athena</div>

            <div className="fields">
              <div className="field">
                <div className="label">Email</div>
                <TextField
                  id="email"
                  fullWidth
                  size="small"
                  placeholder="Email"
                  onChange={updateFields}
                  value={data.email}
                  error={!!error.email}
                  helperText={!!error.email ? error.email : " "}
                />
              </div>

              <div className="field">
                <div className="label">Password</div>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Password"
                  id="password"
                  type={"password"}
                  onChange={updateFields}
                  value={data.password}
                  error={!!error.password}
                  helperText={!!error.password ? error.password : " "}
                />
              </div>

              <div className="field" style={{ marginTop: "-1.3rem" }}>
                {/* <div className="forgotPassword">Forgot Password?</div> */}
              </div>

              <div className="field mt2">
                <>
                  {loading ? (
                    <CustomLoader />
                  ) : (
                    <Button
                      className="button"
                      disableElevation
                      variant="contained"
                      fullWidth
                      onClick={handleSignIn}
                    >
                      Login
                    </Button>
                  )}
                </>

                {serverResponse ? (
                  <div className="mt1">
                    <Alert color={serverResponse.type}>
                      {serverResponse.msg}
                    </Alert>
                  </div>
                ) : null}
              </div>

              {/* <div className="field mt2 center">
                <div className="mediumFont">Or Use</div>
                <div className="socialLinks">
                  <img
                    className="signInSocialImg"
                    id="googleSignIn"
                    src={Image.google}
                  />
                  <img
                    className="signInSocialImg"
                    style={{ cursor: "not-allowed" }}
                    src={Image.facebook}
                  />
                  <img
                    className="signInSocialImg"
                    style={{ cursor: "not-allowed" }}
                    src={Image.linkedin}
                  />
                </div>
              </div> */}

              <div className="field mt2">
                <Button
                  className="button colorprimary"
                  disableElevation
                  variant="outlined"
                  fullWidth
                  onClick={() => navigate(paths.signUp.path)}
                >
                  New Here? Signup now
                </Button>
              </div>
            </div>
          </div>
          <div id="signUpDiv"></div>
        </div>
      </AuthPanel>
    </AuthContainer>
  );
};

export default LoginPage;

export const AuthContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .leftPanel {
  }

  .rightPanel {
    margin: 0 5rem;

    .topBar {
      width: 100%;
      .links {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 2rem;
        color: var(--text2);
        font-size: 0.9rem;

        span {
          cursor: not-allowed;
          transition: 0.3s color;

          &:hover {
            color: var(--primary);
          }
        }
      }
    }

    .loginForm {
      margin-top: 3rem;

      .title {
        font-size: 2.5rem;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }

      .fields {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;

        .field {
          display: flex;
          flex-direction: column;
          z-index: 0;

          .label {
            color: var(--link-active);
            font-weight: 600;
            margin-bottom: 0.2rem;
          }

          .forgotPassword {
            margin-top: 0.2rem;
            font-size: 0.9rem;
            color: var(--primary);
            display: flex;
            justify-content: flex-end;
            cursor: not-allowed;
            transition: 0.2s color;

            &:hover {
              color: var(--text2);
            }
          }

          .socialLinks {
            display: flex;
            gap: 2rem;

            .signInSocialImg {
              width: 1rem;
              background: #fff;
              padding: 1rem;
              cursor: pointer;
              box-shadow: 2px 8px 32px rgb(1 206 175 / 32%);
              transition: 0.1s;
              border-radius: 50%;

              &:hover {
                box-shadow: 0px 2px 3px rgb(1 206 175 / 32%);
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;

    .leftPanel {
      display: none;
    }

    .rightPanel {
      margin: 0;

      .content {
        margin: 1rem auto;
        padding: 2rem;

        .topBar {
          .links {
            gap: 0.5rem;
          }
        }
      }
    }
  }
`;

export const AuthPanel = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .emailVerifiedMsg {
    padding-top: 5rem;
    color: green;
    max-width: 400px;
    display: flex;
    flex-direction: column;

    .msg {
      font-size: 2rem;
      text-align: center;
      letter-spacing: 2px;
      line-height: 1.5;
    }

    .actionField {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
    }
  }

  .loginBg {
    z-index: -1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .content {
    margin-top: 1.5rem;
    max-width: 35rem;

    .mb1 {
      margin-bottom: 1rem;
    }

    .mt1 {
      margin-top: 1rem;
    }

    .mt2 {
      margin-top: 2rem;
    }

    .text {
      color: var(--text2);
      letter-spacing: 1.5px;
      line-height: 1.5rem;
    }

    .col-2 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;
      margin: 0.8rem 0;
      font-size: 0.8rem;
      color: var(--text2);

      .click {
        color: var(--primary);
        font-weight: 700;
        transition: 0.1s color;
        cursor: pointer;

        &:hover {
          color: var(--text2);
        }
      }
    }

    .button {
      height: 3rem;
    }

    .colorprimary {
      color: var(--primary) !important;
    }

    .center {
      display: flex;
      align-items: center;
      color: var(--text1);
      gap: 1rem;
    }

    .mediumFont {
      font-size: 0.9rem;
    }
  }
`;
