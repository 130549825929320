import {
  ListTab,
  OutletContainer,
} from "../../../utils/customStyledComponents";
import styled from "styled-components";
import CustomButton from "../../customComponents/CustomButton";
import CustomTab from "../../customComponents/CustomTab";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import paths from "../../../utils/links";
import {
  handleTopBarTitle,
  setSidebarActive,
} from "../../../utils/helperHandler";
import StartupsList from "./StartupsList";
import { updateNavigation } from "../../../reducers/navigationReducer";
import { globalDispatch } from "../../../providers/GlobalProvider";
import BulkUploadDialog from "./BulkUploadDialog";
import { Dialog } from "@mui/material";

const startupTabs = {
  0: "Joined Startups",
  // 1: "Pending Invitations",
  // 2: "Manage Feedbacks",
  // 3: "Blocked Startups",
  // 4: "Startup Users",
  // 5: "Blocked Users",
};

const Startups = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const dispatch = globalDispatch();
  const [uploadModalOpen, setUploadModal] = useState(false);

  const changeActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const handleUploadModal = (e, reason) => {
    if (reason == "backdropClick") {
      return;
    }
    setUploadModal(!uploadModalOpen);
  };

  const startupForm = () => {
    navigate("/startups/add");
    dispatch(
      updateNavigation({
        title: paths.addStartup.title,
        path: paths.addStartup.link,
      })
    );
  };

  useEffect(() => {
    handleTopBarTitle(dispatch, paths.startups);
    setSidebarActive(paths.startups.id);
  }, []);

  const DisplayTab = ({ currentTab }) => {
    switch (currentTab) {
      case 0:
        return <StartupsList />;
    }
  };

  return (
    <OutletContainer>
      <ListTab>
        <CustomTab
          startupTabs={startupTabs}
          activeTab={activeTab}
          changeActiveTab={changeActiveTab}
        />
        <CustomButton title="Add Startup" onClickHandler={startupForm} />
        <CustomButton title="Bulk Upload" onClickHandler={handleUploadModal} />
      </ListTab>

      <TabContainer>
        <DisplayTab currentTab={activeTab} />
      </TabContainer>

      {uploadModalOpen ? (
        <Dialog open={uploadModalOpen} onClose={handleUploadModal}>
          <BulkUploadDialog handleDialogClose={handleUploadModal} />
        </Dialog>
      ) : (
        <></>
      )}
    </OutletContainer>
  );
};

export default Startups;

const TabContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
`;
