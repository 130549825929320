import { createSlice } from "@reduxjs/toolkit";

export const NavigationDataSlice = createSlice({
  name: "navigation",
  initialState: {
    title: "Home",
    path: "/",
  },
  reducers: {
    updateNavigation: (state, actions) => {
      state["title"] = actions.payload["title"];
      state["path"] = actions.payload["path"];
    },
  },
});

export const { updateNavigation } = NavigationDataSlice.actions;

export default NavigationDataSlice.reducer;
