import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { sendInvite } from "../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../providers/GlobalProvider";
import { DialogBody } from "../../../../utils/customStyledComponents";
import { Validator } from "../../../../utils/helperHandler";
import { Icons } from "../../../../utils/mediaExports";
import CustomButton from "../../../customComponents/CustomButton";

const initialState = {
  companyName: "",
  email: "",
};

const NewApplicantModal = ({ modalHandler, refreshApplications, scheme }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ ...initialState });
  const [error, setError] = useState({ ...initialState });
  const [successDialog, setSuccessDialog] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);

  const dispatch = globalDispatch();
  const controller = new AbortController();

  const {id} = useParams();

  const handleChange = (id, value) => {
    setData({ ...data, [id]: value });
  };

  const submitHandler = async () => {
    let error = { ...initialState };
    setServerResponse(null);
    let isvalid = true;
    Object.keys(data).map((obj, i) => {
      if (data[obj].trim() == "" || data[obj] == -1) {
        isvalid = false;
        error[obj] = `${obj} is required!`;
      }

      if (obj == "email") {
        if (!Validator.validateEmail(data[obj])) {
          isvalid = false;
          error[obj] = `Email is invalid!`;
        }
      }
    });
    setError(error);
    if (!isvalid) return;

    try {
      setLoading(true);
      const response = await sendInvite(
        {
          email: data.email.trim(),
          companyName: data.companyName.trim(),
          scheme: id,
        },
        dispatch,
        controller
      );

      if (response && response.status == 200) {
        setSuccessDialog(true);
        refreshApplications();
      } else if (response) {
        setServerResponse({
          type: "error",
          msg: response.msg || "Something went wrong!",
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessDialog = () => {
    setSuccessDialog(false);
    modalHandler();
  };

  if (successDialog) {
    return (
      <Dialog open={true}>
        <DialogBody>
          <div className="closeButton" onClick={handleSuccessDialog}>
            {Icons.close}
          </div>
          <DialogTitle className="dialogTitle"></DialogTitle>

          <DialogContent className="content">
            <h2 className="invite">
              Invitation Successfully sent to <span>{data.email.trim()}</span>{" "}
              for BIG
            </h2>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomButton
                title={"Continue"}
                onClickHandler={handleSuccessDialog}
              />
            </div>
          </DialogContent>
        </DialogBody>
      </Dialog>
    );
  }

  return (
    <>
      <DialogBody>
        <div className="closeButton" onClick={modalHandler}>
          {Icons.close}
        </div>
        <DialogTitle className="dialogTitle">{`Invite Applicant for BIG - ${scheme?.schemeName}`}</DialogTitle>
        <DialogContent className="content">
          <TextField
            className="firstField"
            label={"Company Name *"}
            size={"small"}
            onChange={(e) => handleChange("companyName", e.target.value)}
            helperText={error.companyName}
            value={data.companyName}
            error={!!error.companyName}
          />
          <TextField
            label={"Email Address *"}
            size={"small"}
            value={data.email}
            error={!!error.email}
            onChange={(e) => handleChange("email", e.target.value)}
            helperText={error.email}
          />

          {/* <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Scheme *</InputLabel>
          <Select
            error={!!error.scheme}
            value={data.scheme}
            label="Scheme *"
            size="small"
            onChange={(e) => handleChange("scheme", e.target.value)}
          >
            <MenuItem value={-1}>
              <FormLabel>Select {"Scheme"}</FormLabel>
            </MenuItem>
            {schemes.map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.title}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText
            style={{color: "var(--errorTextColor)" }}
          >
            {error.scheme}
          </FormHelperText>

        </FormControl> */}

          <div className="actionField">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={submitHandler}
                className="button"
                variant="contained"
              >
                Send Invite
              </Button>
            )}
          </div>

          <div>
            {serverResponse ? (
              <Alert color={serverResponse.type}>{serverResponse.msg}</Alert>
            ) : null}
          </div>
        </DialogContent>
      </DialogBody>
    </>
  );
};

export default NewApplicantModal;
