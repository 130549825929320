import { Radio } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { BigSelector } from "../../../providers/scheme/big/BigProvider";
import { Icons } from "../../../utils/mediaExports";

//from: it is to identify whether applicationid with schemeid and it is only for the ikp side view
//afterApplicationSelect: this is to identify whether it is from ikp side and the application has been selected so that we can display the application view of the applicant

const CustomSchemeTabs = ({ from, active, setActive, isFounder, afterApplicationSelect }) => {
  const tabs = BigSelector((state) => state.bigTabs1);

  const handleChangeTab = (value) => {
    setActive(value);
  };

  useEffect(() => {
    console.log(tabs);
  }, []);

  if (from == "manager" && !afterApplicationSelect) {
    return <></>;
  }

  return (
    <Container>
      <div className="tabContainer">
        {Object.keys(tabs).map((tab, i) => {
          return (
            <div key={i} className="item" onClick={() => handleChangeTab(i)}>
              <span className={`tabItem ${active == i ? "active" : ""}`}>
                <div
                  style={i == 0 ? null : { marginLeft: "1rem" }}
                  className="text"
                >
                  {i == 0
                    ? isFounder
                      ? "Application"
                      : tabs[tab].title
                    : tabs[tab].title}
                </div>

                {isFounder ? (
                  <div
                    style={{
                      width: "1rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Radio size="small" disableRipple checked={i == active} />
                  </div>
                ) : (
                  <div className={`badge ${active == i ? "activeBadge" : ""}`}>
                    {tabs[tab].notifications > 99
                      ? "99+"
                      : tabs[tab].notifications}
                  </div>
                )}

                <span className="arrowIcon">
                  {i + 1 < Object.keys(tabs).length
                    ? Icons.arrowFormward
                    : null}
                </span>
              </span>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default CustomSchemeTabs;

const Container = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;

  .tabContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: var(--text1);
    width: max-content;

    .item {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      .tabItem {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 2px solid var(--borderBottomColor);

        .text {
          font-size: 1.2rem;
        }

        .badge {
          background: #fff;
          font-size: 0.7rem;
          border-radius: 50%;
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .activeBadge {
          background: var(--primary);
        }
      }

      .active {
        border-bottom: 2px solid var(--primary);
      }
    }
  }
`;
