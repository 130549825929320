export class ApplicationStatus {
    static pending = "Pending";
    static applicationRejected = "Application Rejected";
    static applicationPassed = "Application Accepted";
    static invited = "Invited";
    static applied = "Applied";
}

export const ApplicaitonStatusColor = {
    "applicationPassed": "green",
    "applicationRejected": "#EC7063",
    "pending": "gray",
    "applied" : "gray",
    "invited": "gray",
};

export const ApplicationStatusTextColor = {
    "applicationPassed": "#fff",
    "applicationRejected": "#fff",
    "pending": "#fff",
    "applied" : "#fff",
    "invited": "#fff",
}