import { Chip, createTheme } from "@mui/material";
import styled from "styled-components";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#01d2b2",
    },
  },
});

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-top: 1rem;
`;

export const Heading = styled.div`
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1rem;
`;

export const SubHeading = styled.div`
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.8rem;
`;

export const ChipContainer = styled.div`
  margin-bottom: 1rem;
`;

export const ItemChip = styled(Chip)`
  margin: 0 0.5rem 0.5rem 0rem !important;
`;

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  overflow: auto;

  .fields {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 1rem;

    .field {
      display: flex;
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 500px) {
    .fields {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
`;

export const MultipleImageUploadContainer = styled.div`
  border: 2px solid #d0d3d4;
  padding: 1rem;
  border-radius: 4px;
  border-style: dashed;

  .image-container {
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    overflow: hidden;

    .image-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--text1);
      font-size: 0.9rem;
      overflow: hidden;

      .image {
        width: 100px;
        height: 80px;
        border: 1px dashed #d0d3d4;
        border-radius: 20px;
        position: relative;

        .close {
          position: absolute;
          right: -2px;
          top: -2px;
          cursor: pointer;
        }

        img {
          width: 90%;
          height: 100%;
          object-fit: contain;
          display: flex;
          margin: auto auto;
        }
      }

      .name {
        max-width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .add {
      width: 100px;
      height: 80px;
      border: 1px dashed #d0d3d4;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: gray;
      cursor: pointer;
    }
  }
`;

export const OutletContainer = styled.div`
  z-index: 2;
  position: relative;
`;

export const Form = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1rem;

  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const TabContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
  padding: 1rem;
  gap: 1rem;

  .link {
    color: #626567;
    font-size: 0.9rem;
    cursor: pointer;
    font-weight: 500;
    margin-right: 3rem;

    &:hover {
      color: var(--text1);
    }
  }

  .active {
    color: var(--text1);
  }
`;

export const ListTab = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const TabPanel = styled.div``;

export const DialogBody = styled.div`
  position: relative;

  .closeButton {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    transition: opacity 0.1s;

    &:hover {
      opacity: 0.7;
    }
  }

  .closePdfViewer {
    background: #239B56;
    color: #fff;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }

  .dialogTitle {
    display: flex;
    align-items: center;

    .bold {
      font-weight: bold;
      margin-right: 0.2rem;
    }

    .light {
      color: var(--text3);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 300px;

    .firstField {
      margin-top: 0.5rem;
    }

    .fileUploader {
      min-width: 300px;
      width: 100%;
      border: 1px solid gray;
      border-style: dashed;
      padding: 1rem;
      cursor: pointer;
    }

    .actionField {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 2.5rem;

      .button {
        padding: 0.4rem 2rem;
      }
    }

    .invite {
      text-align: center;

      span {
        color: var(--primary);
      }
    }
  }

  .pdfViewerComments {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    height: 100%;
    align-items: center;
    flex: 1;
    position: relative;

    .arrow {
      background: #d0d3d4;
      opacity: 0.7;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      width: 4rem;
      height: 3rem;
      z-index: 1;
      border-radius: 50%;
      transition: 0.1s opacity;

      &:hover {
        opacity: 1;
      }
    }

    .arrowLeft {
      transform: rotate(180deg);
    }

    .pdfView {
      width: 100%;
      height: 100%;
      overflow: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ecf0f1;

      label {
        display: flex;
        justify-content: center;
        margin: 0.2rem 0;
      }
    }

    .commentView {
      width: 300px;
      background: #ecf0f1;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-self: stretch;

      .commentTitle {
        margin-bottom: 0.5rem;
      }

      .commentTextfield {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        textarea {
          resize: none;
          height: 50%;
          width: 100%;
          min-height: 300px;
          box-sizing:border-box
        }

        label {
          color: #239B56;
        }
      }
    }
  }
`;

export const FloatingSubmitButton = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 2rem;
`;

//schemes
export const SchemeTopPanel = styled.div`
  margin: 1.5rem 0;
  width: 100%;
  display: flex;
  flex: 1;
  gap: 2rem;

  .leftPanel {
    .title {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
      color: var(--text1);
    }

    .content {
      color: var(--text3);
      letter-spacing: 1px;
      line-height: 20px;
      text-align: left;
    }
  }

  .rightPanel {
    display: flex;
    width: max-content;
    font-size: 0.9rem;
  }
`;

export const SchemeTabContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  .no-application {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    color: var(--errorTextColor);
  }

  .MuiTabScrollButton-root {
    width: max-content !important;
  }

  .tabs {
    .tabItem {
      width: max-content;
      display: flex;
      align-items: center;
      margin-right: 1rem;

      button {
        min-width: max-content !important;
        max-width: max-content !important;
      }

      .text {
        width: max-content;
        padding: 0;
      }

      .badge {
        background: #fff;
        color: var(--text1);
        font-size: 0.8rem;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 1rem;
        margin-top: -0.3rem;
      }

      .activeBadge {
        background: var(--primary);
      }
    }
  }
`;
