import axios from "axios";
import { logout } from "../../data/network/api/user";
//const BASE_URL = "http://15.207.89.37:8000/api";
 //const BASE_URL = "http://localhost:8000/api";
const BASE_URL = " https://athenabackend.lyf.fyi/api";


let Response = {
  status: 0,
  msg: "",
};

export const postRequest = async ({
  endpoint,
  data = {},
  headers = {},
  dispatch = null,
  controller,
}) => {
  let response = { ...Response };
  try {
    const res = await axios.post(
      `${BASE_URL}${endpoint}`,
      data,
      {
        headers: headers,
      },
      {
        signal: controller.signal,
      }
    );
    response = {
      status: res.status,
      msg: res.data,
    };
  } catch (e) {
    if (e?.code == "ERR_NETWORK") {
      response = {
        status: 400,
        msg: "No Network",
      };
    } else {
      response = handleResponseStatus(e, dispatch);
    }
  } finally {
    return response;
  }
};

export const getRequest = async ({
  endpoint,
  params = {},
  headers = {},
  dispatch = null,
  controller,
}) => {
  let response = { ...Response };
  try {
    const res = await axios.get(
      `${BASE_URL}${endpoint}`,
      {
        params: params,
        headers: headers,
      },
      {
        signal: controller.signal,
      }
    );
    response = {
      status: res.status,
      msg: res.data,
    };
  } catch (e) {
    if (e?.code == "ERR_NETWORK") {
      response = {
        status: 400,
        msg: "No Network",
      };
    } else {
      response = handleResponseStatus(e, dispatch);
    }
  } finally {
    return response;
  }
};

const handleResponseStatus = (e, dispatch) => {
  switch (e?.response?.status) {
    case 500:
      return {
        status: 500,
        msg: e.response?.data || "Internal Server Error!",
      };
    case 404:
      return {
        status: 404,
        msg: "Not found!",
      };
    case 400:
      return {
        status: 400,
        msg: e.response?.data || "Bad Request!",
      };
    case 401:
      logout(dispatch);
      return {
        status: 401,
        msg: "Unauthorized!",
      };
    case 403:
      return {
        status: 403,
        msg: e.response?.data || "Forbidden",
      };
  }
};
