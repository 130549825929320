import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useRef } from "react";
import { useEffect, useState } from "react";

import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

import { useParams } from "react-router-dom";
import {
  checkApplication,
  getAppicationData,
  saveApplicationData,
} from "../../../../../../data/network/api/scheme";
import { uploadFile } from "../../../../../../data/network/api/startupForm";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../providers/GlobalProvider";
import { DialogBody } from "../../../../../../utils/customStyledComponents";
import { Icons, Pdf } from "../../../../../../utils/mediaExports";
import { SchemePhaseTabContainer } from "../ikp/Applications";
import ActionDialog from "./applicationAction/ActionModal";
import CompletionOfForm from "./forms/CompletionOfForm";
import ObjectiveAndPlan from "./forms/ObjectiveAndPlan";
import { saveComment } from "../../../../../../data/network/api/scheme";
import moment from "moment/moment";
import { ApplicaitonStatusColor, ApplicationStatus, ApplicationStatusTextColor } from "../../../../../../utils/constants";

const ApplicantApplicationSide = ({
  application,
  applicationData,
  getApplicationDataHandler,
  applicationDataId,
  from,
}) => {
  const { user } = globalSelector((state) => state.auth);
  const controller = new AbortController();
  const [activeForm, setActiveForm] = useState(null);
  const dispatch = globalDispatch();
  const params = useParams();

  const [data, setData] = useState(null);
  const [applicationMan, setApplicationMan] = useState(null);

  const [selectedAction, setSelectedAction] = useState(-1);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);

  const [uploading, setUploading] = useState(false);

  const handleFile = async (e, dates) => {
    if (e.target.files.length > 0) {
      const startDate = new Date(dates?.startDate);
      const endDate = new Date(dates?.endDate);
      const currentDate = new Date();
      // if (startDate <= currentDate && endDate <= currentDate) {
      const file = e.target.files[0];
      if (file.type != "application/pdf") {
        alert("Invalid file type! Upload PDF");
        return;
      }
      setUploading(true);
      const fileData = new FormData();
      fileData.append("file", file);
      const urlRes = await uploadFile(fileData, dispatch, controller);
      if (urlRes && urlRes.status == 200) {
        const response = await saveApplicationData(
          {
            data: { url: urlRes.msg },
            applicationId: application._id,
            phase: "phase1",
            subPhase: "phase3",
          },
          dispatch,
          controller
        );
        if (response && response.status == 200) {
          alert("PDF Uploaded!");
          handleForm(null, null, true);
        }
      } else {
        alert("Something went wrong! Try again");
      }
      setUploading(false);
      // }
    }
  };

  //if visited this component from manager id then get data
  const getApplicationDataHandlerForManager = async () => {
    try {
      const application = await checkApplication(
        {
          scheme: params.id,
          fromIkp: true,
          applicationId: params.applicationId,
        },
        dispatch,
        controller
      );
      if (application && application.status == 200) {
        const response = await getAppicationData(
          { applicationId: params.applicationId },
          dispatch,
          controller
        );
        if (response && response.status == 200) {
          setData(response.msg);
          setApplicationMan(application.msg);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  useEffect(() => {
    setActiveForm(null);
    if (from == "manager") {
      getApplicationDataHandlerForManager();
    }
  }, [params.id]);

  const handleForm = (value, dates = null, refresh = false) => {
    if (dates) {
      const startDate = new Date(dates.startDate);
      const endDate = new Date(dates.endDate);
      const currentDate = new Date().toISOString();

      // if (startDate > currentDate) {
      //   alert(`Try on ${startDate.toLocaleDateString()}`);
      //   return;
      // } else if (endDate < currentDate) {
      //   alert(`This form and phase has been expired on ${endDate}`);
      //   return;
      // }
    }
    if (refresh) {
      getApplicationDataHandler();
    }
    setActiveForm(value);
  };

  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    setActionDialogOpen(true);
  };

  const handleActionDialog = (e, reason) => {
    if (reason == "backdropClick") return;

    if (actionDialogOpen) {
      setSelectedAction(-1);
    }
    setActionDialogOpen(!actionDialogOpen);
  };

  if (activeForm) {
    switch (activeForm) {
      case 1:
        return (
          <CompletionOfForm
            application={from == "manager" ? applicationMan : application}
            handleForm={handleForm}
            applicationData={
              from == "manager"
                ? data?.phase1?.subPhases?.phase1
                : applicationData?.subPhases?.phase1
            }
            from={from}
          />
        );

      case 2:
        return (
          <ObjectiveAndPlan
            application={from == "manager" ? applicationMan : application}
            handleForm={handleForm}
            applicationData={
              from == "manager"
                ? data?.phase1?.subPhases?.phase2
                : applicationData?.subPhases?.phase2
            }
            from={from}
          />
        );
    }
  }

  if (from == "manager") {
    return (
      <SchemePhaseTabContainer>
              <div className={`statusTextApplication `} style={{background: ApplicaitonStatusColor[applicationMan?.status], color: ApplicationStatusTextColor[applicationMan?.status], marginTop: '1rem'}}>{ApplicationStatus[applicationMan?.status] || ""}</div>
        {applicationMan ? (
          <div className="container">
            <TableContainer className="tableContainer applicantTable">
              <Table sx={{ minWidth: 650 }} aria-label="simple table ">
                <TableHead>
                  <TableRow>
                    <TableCell width={"50px"} align="center">
                      Sl No
                    </TableCell>
                    <TableCell>Task</TableCell>
                    <TableCell>Application Open from</TableCell>
                    <TableCell>Apply Before</TableCell>
                    <TableCell>Progress</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <BuildApplicationPhases
                    handleForm={handleForm}
                    phase={applicationMan.scheme.phases.application}
                    applicationData={data?.phase1?.subPhases}
                    getApplicationDataHandlerForManager={
                      getApplicationDataHandlerForManager
                    }
                    applicationDataId={data?._id}
                    handleFile={handleFile}
                    application={applicationMan}
                    from={from}
                    uploading={uploading}
                  />
                </TableBody>
              </Table>
            </TableContainer>

            <div className="actions">
              <div className="title">Action: </div>
              <div className="input">
                <Select
                  size="small"
                  value={selectedAction}
                  onChange={handleActionManager}
                >
                  <MenuItem value={-1}>
                    <FormLabel>Select</FormLabel>
                  </MenuItem>
                  <MenuItem value={"nextRound"}>
                    <FormLabel>Move to next round</FormLabel>
                  </MenuItem>
                  <MenuItem value={"assign"}>
                    <FormLabel>
                      {applicationMan?.reviewer
                        ? "Revoke Reviewer"
                        : "Assign Reviewer"}
                    </FormLabel>
                  </MenuItem>
                  <MenuItem value={"reject"}>
                    <FormLabel style={{ color: "red" }}>Reject</FormLabel>
                  </MenuItem>
                </Select>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-application">No Application found!</div>
        )}

        <Dialog open={actionDialogOpen} onClose={handleActionDialog}>
          <ActionDialog
            handleActionDialog={handleActionDialog}
            activeAction={selectedAction}
            application={applicationMan}
            getApplicationDataHandlerForManager={
              getApplicationDataHandlerForManager
            }
          />
        </Dialog>
      </SchemePhaseTabContainer>
    );
  }

  return (
    <SchemePhaseTabContainer>
      <div className={`statusTextApplication `} style={{background: ApplicaitonStatusColor[application?.status], color: ApplicationStatusTextColor[application?.status]}}>{ApplicationStatus[application?.status] || "Pending"}</div>
      {application ? (
        <TableContainer className="tableContainer applicantTable">
          <Table sx={{ minWidth: 650 }} aria-label="simple table ">
            <TableHead>
              <TableRow>
                <TableCell width={"50px"} align="center">
                  Sl No
                </TableCell>
                <TableCell>Task</TableCell>
                <TableCell>Application Open from</TableCell>
                <TableCell>Apply Before</TableCell>
                <TableCell>Progress</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <BuildApplicationPhases
                handleForm={handleForm}
                phase={application.scheme.phases.application}
                applicationData={
                  from == "manager"
                    ? data?.phase1?.subPhases
                    : applicationData?.subPhases
                }
                applicationDataId={
                  from == "manager" ? data?._id : applicationDataId
                }
                handleFile={handleFile}
                application={application}
                uploading={uploading}
              />
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="no-application">No Application found!</div>
      )}
    </SchemePhaseTabContainer>
  );
};

const BuildApplicationPhases = ({
  handleForm,
  phase,
  applicationData,
  applicationDataId,
  getApplicationDataHandlerForManager,
  handleFile,
  from,
  uploading,
}) => {
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const [loading, setLoading] = useState(true);

  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const dialogRef = useRef();
  const [fileName, setFileName] = useState("");
  const [comments, setComments] = useState([]);
  const [isCommentsChanged, setCommentsChanged] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    const name = String(applicationData?.phase3?.data?.url).split("/");
    let fileName = name[name.length - 1];
    fileName = fileName.substring(0, fileName.length - 15);
    setFileName(fileName);

    if (applicationData?.phase3?.comments?.length > 0) {
      setComments(applicationData.phase3.comments);
      setLoading(false);
      return;
    }
    const commentsArray = Array(numPages).fill("");
    setComments([...commentsArray]);
    setLoading(false);
  }

  const submitComments = async () => {
    if (!isCommentsChanged) {
      return true;
    }
    try {
      setLoading(true);
      const response = await saveComment(
        {
          values: comments,
          formName: "presentation",
          applicationDataId: applicationDataId,
        },
        dispatch,
        controller
      );
      if (response && response.status == 200) {
        return true;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    return false;
  };

  const handlePdfView = (url) => {
    setPdfDialogOpen(true);
  };

  const closePdfView = async (e, reason) => {
    if (reason == "backdropClick") {
      return;
    }

    if (from == "manager") {
      const isCommentsSaved = await submitComments();
      if (isCommentsSaved) {
        getApplicationDataHandlerForManager();
        setComments([]);
        setPdfDialogOpen(false);
      } else {
        alert("Error saving comments! Try again..");
      }
    } else {
      setComments([]);
      setPdfDialogOpen(false);
    }
  };

  const handlePrev = () => {
    if (pageNumber == 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber == numPages) return;
    setPageNumber(pageNumber + 1);
  };

  const changeComment = (index, value) => {
    setCommentsChanged(true);
    let array = [...comments];
    array[index] = value;
    setComments(array);
  };

  return (
    <>
      <TableRow>
        <TableCell align="center" className="text">
          1
        </TableCell>
        <TableCell>
          <div>
            <div className="text">Completion of Form</div>
            {/* <div className="subtextAction">View form</div> */}
          </div>
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase1?.startDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase1?.endDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>{applicationData?.phase1?.progress}%</TableCell>
        <TableCell>
          <span className="statusText">{applicationData?.phase1?.status}</span>
        </TableCell>
        <TableCell
          className="actionText"
          onClick={() => handleForm(1, phase?.phase1)}
        >
          {from == "manager"
            ? "View"
            : applicationData?.phase1?.progress > 0
            ? "Edit Form"
            : "Fill Form"}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="center" className="text">
          2
        </TableCell>
        <TableCell>
          <div>
            <div className="text">Objective & Work Plan</div>
            {/* <div className="subtextAction">View form</div> */}
          </div>
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase2?.startDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase2?.endDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>{applicationData?.phase2?.progress}%</TableCell>
        <TableCell>
          <span className="statusText">{applicationData?.phase2?.status}</span>
        </TableCell>
        <TableCell
          className="actionText"
          onClick={() => handleForm(2, phase?.phase2)}
        >
          {from == "manager"
            ? "View"
            : applicationData?.phase1?.progress > 0
            ? "Edit Form"
            : "Fill Form"}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="center" className="text">
          3
        </TableCell>
        <TableCell>
          <div>
            <div className="text">
              <span style={{ color: "red" }}>*</span> Presentation Upload
            </div>
            {/* <div className="subtextAction">View uploads</div> */}
          </div>
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase3?.startDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>
          {moment(new Date(phase?.phase3?.endDate)).format("DD/MM/YYYY") ||
            "Failed to retreive!"}
        </TableCell>
        <TableCell>{applicationData?.phase3?.progress}%</TableCell>
        <TableCell>
          <span className="statusText">{applicationData?.phase3?.status}</span>
        </TableCell>
        <TableCell>
          {uploading ? (
            <CircularProgress />
          ) : (
            <div>
              <label className="text">
                {from == "manager" ? (
                  <></>
                ) : (
                  <input
                    onChange={(e) => handleFile(e, phase?.phase1)}
                    type="file"
                    hidden
                  />
                )}

                {from == "manager" ? (
                  <div
                    onClick={() =>
                      handlePdfView(applicationData?.phase3?.data?.url)
                    }
                  >
                    View
                  </div>
                ) : applicationData?.phase3?.progress > 0 ? (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                    }}
                  >
                    ReUpload{" "}
                    <FormLabel style={{ fontSize: "0.7rem" }}>
                      (Only PDF)
                    </FormLabel>
                  </span>
                ) : (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                    }}
                  >
                    Upload{" "}
                    <FormLabel style={{ fontSize: "0.7rem" }}>
                      (Only PDF)
                    </FormLabel>
                  </span>
                )}
              </label>

              {from == "manager" ? (
                <></>
              ) : (
                <div
                  className="subtextAction"
                  onClick={() =>
                    handlePdfView(applicationData?.phase3?.data?.url)
                  }
                >
                  View
                </div>
              )}
              <div className="subtextAction">
                <a
                  style={{ color: "inherit", textDecoration: "none" }}
                  href={applicationData?.phase3?.data?.templateUrl || null}
                  target={"_blank"}
                >
                  Download Template
                </a>
              </div>
            </div>
          )}
        </TableCell>
      </TableRow>

      <Dialog
        open={pdfDialogOpen}
        onClose={closePdfView}
        disableEscapeKeyDown
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1400px", // Set your width here
            },
          },
        }}
        ref={dialogRef}
      >
        <DialogBody>
          <div className="closeButton closePdfViewer" onClick={closePdfView}>
            {Icons.close}
          </div>
          {loading ? <CircularProgress /> : <></>}
          <DialogTitle className="dialogTitle">
            <span className="bold">Presentation: </span>{" "}
            <span className="light">{fileName}</span>
          </DialogTitle>
          <DialogContent className="content pdfViewerComments">
            <div className="arrowLeft arrow" onClick={handlePrev}>
              {Icons.arrowFormward}
            </div>
            <div className="pdfView">
              <Document
                file={applicationData?.phase3?.data?.url}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  pageNumber={pageNumber}
                  className="pdfPage"
                  renderAnnotationLayer={null}
                  renderTextLayer={false}
                />

                <FormLabel className="slideLabel">
                  Slide: {pageNumber}/{numPages}
                </FormLabel>
              </Document>
            </div>

            <div className="commentView">
              <div className="commentTitle">
                <FormLabel>Comment for Slide {pageNumber}</FormLabel>
              </div>

              <div className="commentTextfield">
                <textarea
                  placeholder="Add your comments.."
                  value={comments[pageNumber - 1]}
                  readOnly={from == "manager" ? false : true}
                  onChange={(e) =>
                    changeComment(pageNumber - 1, e.target.value)
                  }
                ></textarea>
                {from == "manager" ? (
                  <FormLabel>
                    Comments will get saved when popup is closed!
                  </FormLabel>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="arrowRight arrow" onClick={handleNext}>
              {Icons.arrowFormward}
            </div>
          </DialogContent>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ApplicantApplicationSide;
