import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getLatestSchemes } from "../../data/network/api/scheme";
import { logout } from "../../data/network/api/user";
import { globalDispatch } from "../../providers/GlobalProvider";
import { updateNavigation } from "../../reducers/navigationReducer";
import paths, { SidebarLinks } from "../../utils/links";
import { Icons, Logo } from "../../utils/mediaExports";
import { setSidebarActive } from "../../utils/helperHandler";
import GlobalStore from "../../store/globalStore";

const Sidebar = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [isMobileScreen, setMobileScreen] = useState(false);
  const [roleId, setRoleId] = useState(null);

  const dispatch = globalDispatch();
  const controller = new AbortController();

  //schemes data
  const [schemes, setSchemes] = useState(null);
  const [activeScheme, setActiveScheme] = useState(null);

  //function to handle click on collapse icon
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  //function to get latest 5 of all schemes
  const getLatestSchemesHandler = async () => {
    try {
      const response = await getLatestSchemes({}, dispatch, controller);
      if (response && response.status == 200) {
        setSchemes(response.msg);
      }
    } catch (err) {}
  };

  const updateNavigationStore = (link, name) => {
    if (name) {
      const title = paths[name].title;
      dispatch(
        updateNavigation({
          title: title,
          path: link,
        })
      );
    }
  };

  useEffect(() => {
    setRoleId(GlobalStore.getState().auth.user.role.roleId);
  }, []);

  //if sidebar collpased then add class to sidebar div to apply collapsed styling
  useEffect(() => {
    try {
      getLatestSchemesHandler();
      if (collapsed) {
        document
          .getElementById("sidebar")
          .classList.toggle("sidebar-collapsed");
      } else {
        document
          .getElementById("sidebar")
          .classList.remove("sidebar-collapsed");
      }
    } catch (e) {
      window.location.reload();
    }
  }, [collapsed]);

  //function to handle navigation from sidebar links and store routename in session storage
  const navigation = (link, name) => {
    setActiveScheme(null);
    sessionStorage.setItem("active-link", name);
    Object.keys(SidebarLinks).map((category) =>
      Object.keys(SidebarLinks[category].links).map((link) =>
        document.getElementById(link).classList.remove("active")
      )
    );
    document.getElementById(name).classList.toggle("active");

    if (isMobileScreen) {
      setCollapsed(true);
    }

    navigate(link);
    updateNavigationStore(link, name);
  };

  //highlight the link when page is refreshed from other than home tab
  useEffect(() => {
    window.addEventListener("resize", windowResize);
    if (window.innerWidth <= 1000) {
      setMobileScreen(true);
    }
  }, []);

  const windowResize = () => {
    if (window.innerWidth <= 1000) {
      setMobileScreen(true);
    } else {
      setMobileScreen(false);
    }
  };

  return (
    <SidebarContainer collapsed={collapsed}>
      <div className="sidebar-overlay" id="sidebar">
        <div className="sidebar-logo">
          <div className="logo">
            <img
              src={Logo.main}
              alt="Athena-2.0 Logo"
              onClick={() => navigation("/", "home")}
            />
          </div>

          <div
            className="shrinkArrow"
            id="shrinkArrow"
            onClick={toggleCollapsed}
          >
            {isMobileScreen
              ? collapsed
                ? Icons.hamburger
                : Icons.close
              : !collapsed
              ? Icons.arrowShrinkLeft
              : Icons.arrowShrinkRight}
          </div>
        </div>

        <div className="sidebar-link-container">
          <div className="sidebar-link-container-relative">

            {Object.keys(SidebarLinks).map((linkCategory, i) => (

              <div className="sidebar-link-subContainer" key={i}>
                {SidebarLinks[linkCategory].title && (!SidebarLinks[linkCategory].hide || isMobileScreen) ? (
                  <div className="heading">
                    <div className="text">
                      {SidebarLinks[linkCategory].title}
                    </div>
                    <hr />
                  </div>
                ) : (
                  <></>
                )}


                {Object.keys(SidebarLinks[linkCategory].links).map(
                  (link, i) => (
                    <>
                      <div className="linkContainer" style={{display: link == "startups" && roleId != "super_admin" ? "none" : ""}}>
                        <div
                          className={`link ${
                            SidebarLinks[linkCategory].links[link].hide
                              ? "extras"
                              : ""
                          }`}
                          id={link}
                          key={i}
                          onClick={() => {
                            if (linkCategory == "schemes") {
                              setSidebarActive(link);
                              setActiveScheme(activeScheme == link ? null : link);
                              if (collapsed) {
                                setCollapsed(false);
                                setActiveScheme(link);
                              }
                              return;
                            }
                            if (link == "logout") {
                              logout(dispatch);
                              return;
                            }
                            navigation(
                              SidebarLinks[linkCategory].links[link].link,
                              link
                            );
                          }}
                        >
                          {SidebarLinks[linkCategory].links[link].icon}
                          <div className="text">
                            {SidebarLinks[linkCategory].links[link].title}
                          </div>
                        </div>

                        <div
                          className="subLinks"
                          style={{
                            display: link == activeScheme ? "flex" : "none",
                          }}
                        >
                          {schemes && schemes[link] && !collapsed && activeScheme==link ? (
                            schemes?.[link].map((scheme, i) => {
                              return (
                                <div
                                  onClick={() => {
                                    window.location.assign(
                                      `/schemes/big/${scheme._id}`
                                    );
                                  }}
                                >
                                  {scheme?.schemeName}
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                          {
                            schemes && schemes?.[link] && schemes?.[link]?.length == 0 ? <>No Calls</> : <></>
                          }
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;

const SidebarContainer = styled.div`
  max-width: ${(props) => (props.collapsed ? "7rem" : "17.6rem")};
  height: 100vh;
  flex: 1;
  position: relative;
  transition: max-width 0.3s;

  .sidebar-overlay {
    height: 100%;

    .sidebar-logo {
      display: flex;
      justify-content: flex-start;
      background: var(--primary);
      padding: 0rem 2rem;
      height: var(--navHeight);
      position: relative;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
        transition: 0.2s;
      }

      .shrinkArrow {
        background: #fff;
        position: absolute;
        right: -10px;
        box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
        border-radius: 8px;
        z-index: 999;
        cursor: pointer;
        top: 50%;
        transform: translate(0%, -50%);

        svg {
          font-size: 1.5rem;
          display: flex;
          padding: 5px;
          color: var(--text1);
        }
      }
    }

    .sidebar-link-container {
      background: linear-gradient(213.56deg, #00d1b1 30.06%, #0074d7 166.45%);
      width: 100%;
      height: calc(100vh - var(--navHeight));
      z-index: 5;
      position: relative;
      overflow-y: auto;

      .sidebar-link-container-relative {
        background: rgba(0, 0, 0, 0.1);
        padding: 1rem 2rem;
        max-height: max-content;
        min-height: calc(100vh - var(--navHeight));

        .sidebar-link-subContainer {
          .heading {
            display: flex;
            gap: 1rem;
            align-items: center;
            margin: 1.5rem 0;

            .text {
              color: #fff;
              opacity: 0.8;
              font-weight: 500;
              line-height: 15px;
              text-transform: uppercase;
              font-size: 0.9rem;
              letter-spacing: 1px;
            }

            hr {
              flex: 1;
              height: 1px;
              color: #fff;
              background: #fff;
              border: none;
            }
          }

          .link {
            color: #fff;
            cursor: pointer;
            padding: 0.5rem 0.9rem;
            border-radius: 8px;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            transition: 0.2s;
            height: 2.5rem;

            .text {
              font-size: 0.9rem;
              letter-spacing: 0px;
              font-weight: 100;
              transition: 0.3s;
              transition-timing-function: linear;
              overflow: clip;
              transform: translateX(0vw);
            }

            svg {
              max-width: 1.3rem;
              min-width: 1.3rem;
              font-size: 1.3rem;
            }

            &:hover {
              background: var(--link-active);
            }
          }

          .extras {
            display: none;
          }

          .active {
            background: var(--link-active);
          }

          .subLinks {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 0 1rem;
            color: #fff;

            div {
              cursor: pointer;
              transition: 0.2s;

              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        display: block !important;
        width: 6px;
        background-color: #61758a !important;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 12px !important;
        background: #00244b;
      }
    }
  }

  .sidebar-collapsed {
    .sidebar-logo {
      .logo {
        width: 50px;
      }
    }

    .sidebar-link-container {
      .sidebar-link-container-relative {
        .sidebar-link-subContainer {
          .heading {
            .text {
              display: none;
            }
          }

          .link {
            .text {
              transform: translateX(-100vw);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    max-width: ${(props) => (props.collapsed ? "0rem" : "17rem")};
    position: fixed;
    z-index: 5;

    .sidebar-overlay {
      .sidebar-logo {
        min-width: 100vw;
        display: flex;

        .shrinkArrow {
          right: 1rem;
        }
      }

      .sidebar-link-container {
        transition: 0.3s;

        .sidebar-link-container-relative {
          .sidebar-link-subContainer {
            .extras {
              display: flex;
            }
          }
        }
      }
    }

    .sidebar-collapsed {
      .sidebar-logo {
        .logo {
          width: 100%;
        }
      }

      .sidebar-link-container {
        transform: translateX(-100vw);
      }
    }
  }
`;


