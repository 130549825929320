import React from 'react'
import {
  Provider,
  createStoreHook,
  createDispatchHook,
  createSelectorHook,
} from 'react-redux'
import BigSchemeStore from '../../../store/scheme/big/big';

const MyContext = React.createContext(null)

// Export your custom hooks if you wish to use them in other files.
export const BigStore = createStoreHook(MyContext)
export const BigDispatch = createDispatchHook(MyContext)
export const BigSelector = createSelectorHook(MyContext)

export function BigProvider({ children }) {
  return (
    <Provider context={MyContext} store={BigSchemeStore}>
      {children}
    </Provider>
  )
}