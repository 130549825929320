import { ThemeProvider } from "@emotion/react";
import { Provider, useDispatch } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/dashboard/Home";
import OtherUsers from "./components/dashboard/otherUsers/OtherUsers";
import Startups from "./components/dashboard/startups/Startups";
import TabContainer from "./components/dataCreator/TabContainer";
import { theme } from "./utils/customStyledComponents";
import Dashboard from "./pages/Dashboard";
import GlobalStore from "./store/globalStore";
import LoginPage from "./pages/auth/Login";
import PrivateRoute from "./pages/auth/PrivateRoute";
import BIG from "./components/schemes/ikp/big/Big";
import SignUp from "./pages/auth/Signup";
import paths from "./utils/links";
import { GlobalProvider } from "./providers/GlobalProvider";
import { SnackbarProvider } from "notistack";
import StartupData from "./components/dashboard/startups/StartupData";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalProvider>
        <Routes>
          <Route path="/auth">
            <Route path={paths.login.path} element={<LoginPage />} />
            <Route path={paths.signUp.path} element={<SignUp />} />
          </Route>

          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          >
            <Route index element={<Home />} />
            <Route path="/startups">
              <Route index element={<Startups />} />
              <Route path="/startups/add" element={<TabContainer />} />
              {/* <Route
                path="/startups/:id"
                element={<TabContainer view={true} />}
              /> */}

              <Route path="/startups/:id" element={<StartupData />} />
            </Route>
            <Route path="/otherUsers">
              <Route index element={<OtherUsers />} />
            </Route>

            <Route path="/schemes">
              <Route path="/schemes/big/:id" element={<BIG />} />
              <Route
                path="/schemes/big/:id/:applicationId"
                element={<BIG from={"manager"} />}
              />
            </Route>
          </Route>
        </Routes>
      </GlobalProvider>
    </ThemeProvider>
  );
}

export default App;
