import styled from "styled-components";

const CustomButton = ({ title, onClickHandler }) => {
  return (
    <MyButton onClick={onClickHandler}>
      <span>{title}</span>
    </MyButton>
  );
};

export default CustomButton;

const MyButton = styled.div`
  background: var(--primary);
  cursor: pointer;
  min-width: max-content;
  height: max-content;
  padding: 0.7rem 1.2rem;
  border-radius: var(--borderRadius);
  outline: none;
  box-shadow: 2px 8px 32px rgb(1 206 175 / 32%);
  position: relative;

  span {
    position: relative;
    z-index: 2;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 0.9rem;
    color: #fff;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #00a189;
    width: 0%;
    border-radius: var(--borderRadius);
    z-index: 1;
    transition: 0.3s;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
`;
