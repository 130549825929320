import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { globalDispatch } from "../../providers/GlobalProvider";
import { OutletContainer } from "../../utils/customStyledComponents";
import { handleTopBarTitle, setSidebarActive } from "../../utils/helperHandler";
import paths, { SidebarLinks } from "../../utils/links";

const Home = () => {
    const dispatch = globalDispatch();

    useEffect(() => {
        handleTopBarTitle(dispatch, {title: paths.home.title, path: paths.home.path});
        setSidebarActive(paths.home.id);
    }, []);
    
    return (
        <OutletContainer>
            
        </OutletContainer>
    )
}

export default Home;
