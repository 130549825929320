import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const PageNavigation = ({ paths, active }) => {
  const navigate = useNavigate();

  return (
    <Container>
      {paths.map((path, i) => {
        return (
          <div className="pathContainer" key={i}>
            <div
              className={`text ${path.text == active ? "active" : ""} ${
                path.block ? "block" : ""
              }`}
              onClick={() => navigate(path.link)}
            >
              {path.text}
            </div>
            {paths.length - 1 == i ? null : <div className="bar">/</div>}
          </div>
        );
      })}
    </Container>
  );
};

export default PageNavigation;

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .pathContainer {
    display: flex;
    font-size: 0.9rem;
    color: var(--text1);

    .text {
      cursor: pointer;
      transition: 0.1s color;

      &:hover {
        color: var(--primary);
      }
    }

    .active {
      color: var(--primary);
    }

    .block {
      color: var(--text3);
      cursor: default;
      pointer-events: none;
    }

    .bar {
      margin: 0 0.3rem;
    }
  }
`;
