import {
    Autocomplete,
    Button,
    CircularProgress,
    DialogContent,
    DialogTitle,
    TextField,
  } from "@mui/material";
  import { MuiChipsInput } from "mui-chips-input";
  import { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { saveUser } from "../../../data/network/api/user";
import { globalDispatch, globalSelector } from "../../../providers/GlobalProvider";
  import { updateData } from "../../../reducers/startupform/formReducer";
  import { DialogBody } from "../../../utils/customStyledComponents";
  import { Icons } from "../../../utils/mediaExports";
  import { domainAndSectors } from "../../../utils/optionValues";
  
  const VendorAddModal = ({ handleDialogClose }) => {
    const [data, setData] = useState({
      name: "",
      natureOfWork: "",
      type: "vendor",
    });
    const [loading, setLoading] = useState(false);
    const fields = [
      "_id",
      "name",
      "natureOfWork",
    ];
    let vendorData = globalSelector((state) => state.startup.association.vendors);
    const dispatch = globalDispatch();
    const controller = new AbortController();
  
    useEffect(() => {
      setData({ ...data, name: vendorData.name.value });
    }, []);
  
    // useEffect(() => {
    //   console.log(vendorData);
    // }, [vendorData]);
  
    const handleChange = (id, value) => {
      setData({ ...data, [id]: value });
    };
  
    const handleSubmit = async () => {
      setLoading(true);
      await addUser();
      setLoading(false);
    };
  
    const addUser = async () => {
      const response = await saveUser(data, dispatch, controller);
      if (response && response?.status == 200) {
        const data = response?.msg;
        fields.map((field, i) => {
          dispatch(
            updateData({
              category: "association",
              subCategory: "vendors",
              id: field,
              type: "value",
              value: data?.[field] || "",
            })
          );
        });
        handleDialogClose();
      }
    };
  
    return (
      <DialogBody>
        <div className="closeButton" onClick={handleDialogClose}>
          {Icons.close}
        </div>
        <DialogTitle className="dialogTitle">Add Vendor</DialogTitle>
        <DialogContent className="content">
          <TextField
            className="firstField"
            label={"Vendor Name"}
            size={"small"}
            value={data.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
          <TextField
            label={"Nature of Work"}
            size={"small"}
            value={data.natureOfWork}
            onChange={(e) => handleChange("natureOfWork", e.target.value)}
          />
  
          <div className="actionField ">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                className="button"
                onClick={handleSubmit}
                variant="contained"
              >
                Add
              </Button>
            )}
          </div>
        </DialogContent>
      </DialogBody>
    );
  };
  
  export default VendorAddModal;
  