import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    list: [],
};

const StartupListDataSlice = createSlice({
    name: "startupList",
    initialState: {...INITIAL_STATE},
    reducers: {
        updateStartupList: (state, actions) => {
            state.list = actions.payload.value;
        }
    }
});

export const { updateStartupList } = StartupListDataSlice.actions;  

export default StartupListDataSlice.reducer;