export const domainAndSectors = [
  {
    title: "Automobile",
    value: "automobile",
  },
  {
    title: "Information Technology",
    value: "it",
  },
];


export const trlLevels = [
  {
    title: "Basic principles observed and reported",
    value: 1,
  },
  {
    title: "Technology concept and/or application formulated.",
    value: 2,
  }
];

export const investmentsKind = [
  {
    title: "Debt",
    value: "debt",
  },
  {
    title: "Equity",
    value: "equity",
  },
]

export const gender = [
  {
    title: "Female",
    value: "female",
  },
  {
    title: "Male",
    value: "male",
  },
  {
    title: "Others",
    value: "others",
  },
];

export const educationQualification = [
  {
    title: "Highschool",
    value: "highschool",
  },
  {
    title: "Bachelors",
    value: "bachelors",
  },
  {
    title: "Masters",
    value: "masters",
  },
];

export const planDuration = [
  {
    title: "3 Months",
    value: "3_months",
  },
  {
    title: "6 Months",
    value: "6_months",
  },
  {
    title: "1 year",
    value: "1_year",
  },
  {
    title: "5 year",
    value: "5_year",
  },
];

export const schemes = [
  {
    id: "big",
    title: "Biotechnology Ignition Grant",
    value: "big",
  },
  {
    id: "prayas",
    title: "Prayas Grant",
    value: "prayas",
  }
]