import { combineReducers, configureStore } from "@reduxjs/toolkit";
import BigTabsReducer1  from "../../../reducers/schemes/big/applicantee/tabs";

const combinedReducer = combineReducers({
  bigTabs1: BigTabsReducer1,
});

const rootReducer = (state, action) => {
  //if logout is performed then reset all states
//   if (action.type == "navigation/updateAuth") {
//     if (action.payload.isAuthenticated == false) {
//       state = undefined;
//     }
//   }
  return combinedReducer(state, action);
}

const BigSchemeStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});

export default BigSchemeStore;
