import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MuiChipsInput } from "mui-chips-input";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveUser } from "../../../data/network/api/user";
import { globalDispatch, globalSelector } from "../../../providers/GlobalProvider";
import { updateData } from "../../../reducers/startupform/formReducer";
import { DialogBody } from "../../../utils/customStyledComponents";
import { Icons } from "../../../utils/mediaExports";

const IncubatorAddModal = ({ handleDialogClose }) => {
  const [data, setData] = useState({
    name: "",
    address: "",
    since: "",
    focusAreas: "",
    type: "incubator",
  });
  const [loading, setLoading] = useState(false);
  const fields = ["_id", "name", "address", "since", "focusAreas"];
  let incubatorData = globalSelector(
    (state) => state.startup.association.incubators
  );
  const dispatch = globalDispatch();
  const controller = new AbortController();

  useEffect(() => {
    setData({ ...data, name: incubatorData.name.value });
  }, []);

  useEffect(() => {}, []);

  const handleChange = (id, value) => {
    setData({ ...data, [id]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await addUser();
    setLoading(false);
  };

  const addUser = async () => {
    const response = await saveUser(data, dispatch, controller);
    if (response && response?.status == 200) {
      const data = response?.msg;
      console.log(data);
      fields.map((field, i) => {
        dispatch(
          updateData({
            category: "association",
            subCategory: "incubators",
            id: field,
            type: "value",
            value: data?.[field] || "",
          })
        );
      });
      handleDialogClose();
    }
  };

  return (
    <DialogBody>
      <div className="closeButton" onClick={handleDialogClose}>
        {Icons.close}
      </div>
      <DialogTitle className="dialogTitle">Add Incubator</DialogTitle>
      <DialogContent className="content">
        <TextField
          className="firstField"
          label={"Incubator Name"}
          size={"small"}
          value={data.name}
          onChange={(e) => handleChange("name", e.target.value)}
        />

        <TextField
          label={"Address"}
          size={"small"}
          multiline
          rows={3}
          value={data.address}
          onChange={(e) => handleChange("address", e.target.value)}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={"Incubator Since "}
            inputFormat="DD/MM/YYYY"
            onChange={(newValue) => {
              handleChange("since", new Date(newValue).toLocaleDateString())
            }}
            disableFuture
            value={data.since}
            renderInput={(params) => (
              <TextField fullWidth {...params} size="small" />
            )}
          />
        </LocalizationProvider>

        <MuiChipsInput
          value={data.focusAreas}
          onChange={(value) => handleChange("focusAreas", value)}
          size={"small"}
          label="Focus Areas"
        />

        <div className="actionField ">
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              className="button"
              onClick={handleSubmit}
              variant="contained"
            >
              Add
            </Button>
          )}
        </div>
      </DialogContent>
    </DialogBody>
  );
};

export default IncubatorAddModal;
