import {
  Button,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import {
  getApplicationSubphaseData,
  saveApplicationData,
  saveComment,
} from "../../../../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../../../../providers/GlobalProvider";
import { Icons } from "../../../../../../../utils/mediaExports";
import CustomButton from "../../../../../../customComponents/CustomButton";
import { PhaseContainer } from "./CompletionOfForm";
import styled from "styled-components";
import { useRef } from "react";
import { useSnackbar } from "notistack";
import { Add } from "@mui/icons-material";
import GlobalStore from "../../../../../../../store/globalStore";

const ObjectiveAndPlan = ({
  application,
  handleForm,
  applicationData,
  from,
}) => {
  const [data, setData] = useState([]);
  const user = GlobalStore.getState().auth.user;

  const dispatch = globalDispatch();
  const controller = new AbortController();

  const tableHeadRef = useRef();

  const [openComments, setOpenComments] = useState(false);
  const [tableHeadCellHeight, setTableHeadCellHeight] = useState(0);
  const [canComment, setCanComment] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  function outputSize() {
    const height = document.getElementById("tableCell").offsetHeight;
    if (height != tableHeadCellHeight) {
      setTableHeadCellHeight(height);
    }
  }

  useEffect(() => {
    getApplicationPhaseDataHandler();
    const element = document.getElementById("tableCell");
    new ResizeObserver(outputSize).observe(element);
    if (user?.role?.roleCategory == "ikp") {
      setCanComment(true);
    }
  }, []);

  const getApplicationPhaseDataHandler = async () => {
    const response = await getApplicationSubphaseData(
      {
        phase: "phase1",
        subPhase: "phase2",
        dataId: applicationData?.data,
      },
      dispatch,
      controller
    );
    console.log(response);
    if (response && response.status == 200) {
      setData(response.msg?.objectives);
    }
  };

  const handleApproveCheckbox = (_id, e) => {
    data.map((obj, i) => {
      if (_id == obj._id) {
        let array = [...data];
        let o = { ...data[i] };
        o.status = e.target.checked ? "approved" : "pending";
        array.splice(i, 1, o);
        setData(array);
      }
    });
  };

  const handleTextareaValue = (_id, value) => {
    data.map((obj, i) => {
      if (_id == obj._id) {
        let array = [...data];
        let o = { ...data[i] };
        o.comment = value;
        array.splice(i, 1, o);
        setData(array);
      }
    });
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  const changeState = (index, id, value) => {
    let array = [...data];
    array[index][id] = value;
    setData(array);
  };

  const addRow = () => {
    setData([...data, { objective: "",methodolody: "", alternateStartegies: "" }]);
  };

  const removeRow = (index) => {
    let array = [...data];
    array.splice(index, 1);
    setData(array);
  };

  const submitData = async () => {
    const response = await saveApplicationData(
      {
        data: data,
        applicationId: application._id,
        phase: "phase1",
        subPhase: "phase2",
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      alert("Objective and Plan submitted..");
      handleForm(null, null, true);
    }
  };

  const saveCommentHandler = async (_id, name) => {
    const values = data.find((o) => o._id == _id);
    const response = await saveComment(
      {
        formId: applicationData?.data,
        id: _id,
        formName: "objective",
        values: {
          comment: values.comment,
          status: values.status,
        },
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler(`Comment for ${name} submitted!`, "success");
    }
  };

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  return (
    <PhaseContainer>
      <div className="header">
        <div className="title">Proposal Objective and Work Plan</div>
        <div className="closeIcon" onClick={() => handleForm(null)}>
          {Icons.close}
        </div>
      </div>
      <hr />

      <div className="row">
        <TableContainer className="tableContainer objectiveTable">
          <Table aria-label="simple table">
            <TableHead ref={tableHeadRef}>
              <TableRow>
                <TableCell id="tableCell" className="tc-50">
                  Sl No
                </TableCell>
                <TableCell className="tc-200">Objective</TableCell>
                <TableCell className="tc-200">
                  Methodology / Experimental Design, Work Plan
                </TableCell>
                <TableCell className="tc-200">Alternate strategies</TableCell>
                {from == "manager" ? (
                  <></>
                ) : (
                  <TableCell className="tc-50"></TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, i) => {
                return (
                  <TableRow>
                    <TableCell
                      style={{
                        verticalAlign: "top",
                        background: row.status == "approved" ? "#58d68d" : "",
                        padding: "1.5rem 0",
                      }}
                      className=""
                      align="center"
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell>
                      <textarea
                        readOnly={from == "manager"}
                        value={row.objective}
                        onChange={(e) => {
                          changeState(i, "objective", e.target.value);
                        }}
                      ></textarea>
                    </TableCell>
                    <TableCell>
                      <textarea
                        readOnly={from == "manager"}
                        value={row.methodolody}
                        onChange={(e) => {
                          changeState(i, "methodolody", e.target.value);
                        }}
                      ></textarea>
                    </TableCell>
                    <TableCell>
                      <textarea
                        readOnly={from == "manager"}
                        value={row.alternateStartegies}
                        onChange={(e) => {
                          changeState(i, "alternateStartegies", e.target.value);
                        }}
                      ></textarea>
                    </TableCell>
                    {from == "manager" ? (
                      <></>
                    ) : (
                      <TableCell>
                        <div className="actions">
                          <div
                            className="action delete"
                            onClick={() => removeRow(i)}
                          >
                            {Icons.delete}
                          </div>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}

              {/* {from == "manager" ? (
                <></>
              ) : (
                <TableRow>
                  <TableCell
                    style={{
                      verticalAlign: "top",
                      padding: '1.5rem 0',
                    }}
                    className=""
                    align="center"
                  >
                    {data.length + 1}
                  </TableCell>
                  <TableCell>
                    <textarea
                      onChange={(e) => changeState("objective", e.target.value)}
                      value={state.objective}
                    ></textarea>
                  </TableCell>
                  <TableCell>
                    <textarea
                      onChange={(e) =>
                        changeState("methodolody", e.target.value)
                      }
                      value={state.methodolody}
                    ></textarea>
                  </TableCell>
                  <TableCell>
                    <textarea
                      onChange={(e) =>
                        changeState("alternateStartegies", e.target.value)
                      }
                      value={state.alternateStartegies}
                    ></textarea>
                  </TableCell>

                  <TableCell>
                    <div className="actions">
                      <div onClick={addRow} className="save action">
                        {Icons.save}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>

        <CommentsContainer openComments={openComments} top={70}>
          <div className="arrow" onClick={() => setOpenComments(!openComments)}>
            {Icons.doubleArrow}
          </div>
          <div className="fields">
            <TableContainer className="tableContainer commentsTable">
              <Table aria-label="simple table">
                <TableHead ref={tableHeadRef}>
                  <TableRow style={{ height: tableHeadCellHeight + "px" }}>
                    <TableCell className="">Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((data, i) => {
                    
                    return (
                      <TableRow>
                        <TableCell>
                          <div className="commentField">
                            <div className="commentsSection">
                              <span className="heading">
                                Objective: {i + 1}
                              </span>
                              <textarea
                                onChange={(e) =>
                                  handleTextareaValue(data._id, e.target.value)
                                }
                                value={data?.comment}
                                readOnly={!canComment}
                              ></textarea>
                            </div>

                            {from == "manager" ? (
                              <div className="buttons">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        data?.status == "approved"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        handleApproveCheckbox(data?._id, e)
                                      }
                                    />
                                  }
                                  label="Ok"
                                />
                                <Button
                                  onClick={() =>
                                    saveCommentHandler(
                                      data?._id,
                                      `Objective ${i + 1}`
                                    )
                                  }
                                  className="muiBtn"
                                  variant="outlined"
                                >
                                  Save
                                </Button>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </CommentsContainer>
      </div>

      {from == "manager" ? (
        <></>
      ) : (
        <div className="fields mt1">
          <div className="addRowButton">
            <Button variant="outlined" startIcon={<Add />} onClick={addRow}>
              Add Row
            </Button>
          </div>

          <div className="buttons mt1">
            <CustomButton title="Submit" onClickHandler={submitData} />
          </div>
        </div>
      )}
    </PhaseContainer>
  );
};

export default ObjectiveAndPlan;

const CommentsContainer = styled.div`
  width: ${(props) => (props.openComments ? "25rem" : "2rem")};
  transition: 0.2s width;
  display: flex;
  min-height: 100%;
  max-height: 100%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;

  .arrow {
    background: var(--primary);
    height: 100%;
    min-width: 2rem;
    max-width: 2rem;
    cursor: pointer;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

    svg {
      font-size: 1.7rem;
      color: #fff;
      transform: ${(props) =>
        props.openComments ? "rotate(0deg)" : "rotate(180deg)"};
      margin-top: 0.5rem;
      justify-content: center;
      width: 100%;
    }
  }

  .fields {
    border: 1px solid #d0d3d4;
    border-left: none;
    display: ${(props) => (props.openComments ? "flex" : "none")};

    .commentsTable {
      background: #fff;
      table {
        thead {
          tr {
            height: auto;
            th {
              height: auto !important;
              overflow: hidden;
              text-overflow: clip;
            }
          }
        }

        tbody {
          tr {
            height: 180px;

            td {
              padding: 0;
              vertical-align: top;
              height: inherit;

              .commentField {
                display: ${(props) => (props.openComments ? "flex" : "none")};
                flex-direction: column;
                width: 100%;
                padding: 0 !important;
                height: 100%;

                span {
                  margin-bottom: 0.1rem;
                }

                .commentsSection {
                  width: 100%;
                  height: 100%;
                  border-right: none;
                  border-left: none;
                  display: flex;
                  flex-direction: column;

                  textarea {
                    resize: none;
                    width: 100%;
                    padding: 1rem;
                    box-sizing: border-box;
                    color: var(--text1);
                    height: 100%;
                    border: 1px solid #d0d3d4;
                    outline: none;
                    border-radius: 8px;
                  }
                }

                .buttons {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .muiBtn {
                    height: 2rem;
                    color: var(--primary) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
