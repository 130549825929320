import { getRequest, postRequest } from "../../../utils/axiosClient/axios";
import { getToken } from "./startupForm";
import { logout } from "./user";

export const getLatestSchemes = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await getRequest({
    endpoint: "/scheme/getLatestSchemes",
    params: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
}

export const getSchemeAssociates = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/getSchemeAssociates",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
}

export const assignAssociateForApplication = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/assignAssociate",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
}

export const sendInvite = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/sendInvite",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

export const fetchApplications = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/fetchApplications",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request from founder side to check for application
export const checkApplication = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/checkApplication",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to get application data from application Id
export const getAppicationData = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/getApplicationData",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to save application data for big
export const saveApplicationData = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/saveApplicationData_Big",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to get application subphase data
export const getApplicationSubphaseData = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/getApplicationPhaseData",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};


//api request to add comments 
export const saveComment = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/saveComment",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to change status of application 
export const changeApplicationStatus = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/changeApplicationStatus",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to get scheme details
export const getSchemeDetails = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/getSchemeDetails",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};


