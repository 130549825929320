import { updateNavigation } from "../reducers/navigationReducer";
import validator from "validator";
import { SidebarLinks } from "./links";

export const handleTopBarTitle = (dispatch, path, title = null, dynamicPath=null) => {
  dispatch(
    updateNavigation({
      title: title || path.title,
      path: dynamicPath || path.link,
    })
  );
};

export const setSidebarActive = (name) => {
  sessionStorage.setItem("active-link", name);
  Object.keys(SidebarLinks).map((category) =>
    Object.keys(SidebarLinks[category].links).map((link) =>
      document.getElementById(link).classList.remove("active")
    )
  );
  document.getElementById(name).classList.toggle("active");
};

export class Validator {
  static validateEmail(email) {
    if (validator.isEmail(email)) return true;
    return false;
  }
}
