import {
  Autocomplete,
  Button,
  CircularProgress,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MuiChipsInput } from "mui-chips-input";
import styled from "styled-components";
import GlobalStore from "../../store/globalStore";
import {
  MultipleImageUploadContainer,
  theme,
} from "../../utils/customStyledComponents";
import { Gif, Icons } from "../../utils/mediaExports";

const filter = createFilterOptions();

const urlIds = ["mou", "productGallery"];

export const BuildComponent = ({
  dataField,
  reducerData,
  handleChange,
  options = {},
  handleImageGallery,
  removeFileFromArray,
  addToArray,
  removeRow,
  handleEditRow,
  isEdit,
  currentEditRow,
  handleFile,
  handleSearch,
  removeFile,
  loading = false,
  submitHandler = () => {},
  formFields,
}) => {
  let value = "";
  let helperText = "";
  let error = "";
  let isActive = false;

  try {
    value = reducerData[dataField.fieldName].value;

    helperText =
      reducerData[dataField.fieldName].error == ""
        ? " "
        : reducerData[dataField.fieldName].error;

    error = !!reducerData[dataField.fieldName].error;

    isActive = !!reducerData[dataField.fieldName].active;
  } catch (e) {
    // console.log(e);
  }
  
  switch (options.inputType) {
    case "file":
      return (
        <BuildFileUploader
          dataField={dataField}
          file={reducerData[dataField.fieldName]?.value}
          options={options}
          handleFile={handleFile}
          removeFile={removeFile}
          error={error}
          helperText={helperText}
        />
      );

    case "text":
      return (
        <TextField
          label={options.title}
          id={dataField.fieldName}
          size="small"
          onChange={(e) =>
            handleChange(
              dataField.fieldName,
              e.target.value,
              dataField.category,
              dataField.subCategory
            )
          }
          value={value}
          helperText={helperText}
          error={error}
          multiline={options.multiline}
          rows={options.rows}
          style={
            isActive
              ? options.gridColumn
                ? { gridColumn: options.gridColumn }
                : null
              : { display: "none" }
          }
        />
      );

    case "radio":
      return (
        <div
          style={{
            justifyContent: "center",
            display: isActive ? "flex" : "none",
            flexDirection: "column",
            gridColumn: options.gridColumn || null,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              {options.title}: &nbsp;
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={value}
              onChange={(e) =>
                handleChange(
                  dataField.fieldName,
                  e.target.value,
                  dataField.category,
                  dataField.subCategory
                )
              }
            >
              {options.options.map((option, i) => {
                return (
                  <FormControlLabel
                    value={option.value}
                    control={<Radio />}
                    label={option.title}
                    key={i}
                  />
                );
              })}
            </RadioGroup>
          </div>

          <span>&nbsp;</span>
        </div>
      );

    case "date":
      return (
        <div style={!isActive ? { display: "none" } : null}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={options.title}
              inputFormat="DD/MM/YYYY"
              onChange={(newValue) => {
                handleChange(
                  dataField.fieldName,
                  new Date(newValue).toString(),
                  dataField.category,
                  dataField.subCategory
                );
              }}
              value={value}
              renderInput={(params) => (
                <TextField fullWidth {...params} size="small" />
              )}
            />
          </LocalizationProvider>
          <FormHelperText
            style={error ? { color: "var(--errorTextColor)" } : null}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{helperText ? helperText : <>&nbsp;</>}
          </FormHelperText>
        </div>
      );

    case "button":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gridColumn: options.gridColumn || null,
          }}
        >
          <ThemeProvider theme={theme}>
            <Button
              variant="contained"
              onClick={() => {
                if (isEdit) {
                  addToArray(dataField, currentEditRow, true);
                } else {
                  addToArray(dataField);
                }
              }}
              style={{ minWidth: "10rem" }}
            >
              {isEdit ? "Edit" : options.title}
            </Button>
          </ThemeProvider>
        </div>
      );

    case "product-gallery":
      return (
        <MultipleImageUploadContainer style={{ gridColumn: "1/-1" }}>
          <FormLabel>{options.title}</FormLabel>
          <div className="image-container">
            {value.map((image, i) => {
              return (
                <BuildImageViewer
                  key={i}
                  image={image}
                  i={i}
                  removeFileFromArray={removeFileFromArray}
                  dataField={dataField}
                />
              );
            })}
            <label className="add">
              <input
                multiple
                type={"file"}
                onChange={(e) =>
                  handleImageGallery(
                    e,
                    dataField.fieldName,
                    dataField.category,
                    dataField.subCategory
                  )
                }
                hidden
              />
              {Icons.plus}
            </label>
          </div>
        </MultipleImageUploadContainer>
      );

    case "select":
      return (
        <FormControl>
          <InputLabel id="demo-simple-select-label">
            {dataField.title}
          </InputLabel>
          <Select
            error={error}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label={dataField.title}
            size="small"
            onChange={(e) =>
              handleChange(
                dataField.fieldName,
                e.target.value,
                dataField.category,
                dataField.subCategory
              )
            }
          >
            <MenuItem value={-1}>
              <FormLabel>Select {options.title}</FormLabel>
            </MenuItem>
            {options.options.map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.title}
                </MenuItem>
              );
            })}
          </Select>

          <FormHelperText
            style={error ? { color: "var(--errorTextColor)" } : null}
          >
            {helperText ? helperText : <>&nbsp;</>}
          </FormHelperText>
        </FormControl>
      );

    case "viewer":
      return (
        <div style={{ gridColumn: "1/-1", display: isEdit ? "none" : null }}>
          {reducerData?.array?.map((object, index) => {
            return (
              <SummaryRow key={index}>
                <div className="content">
                  {Object.keys(object).map((keyname, i) => (
                    <div className="row">
                      <div className="heading">{keyname}</div>
                      <div className="desc">
                        {urlIds.includes(keyname)
                          ? keyname == "productGallery"
                            ? object?.[keyname]?.map(
                                (object) => object.name + ", "
                              )
                            : object[keyname]?.url
                          : object[keyname]}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="action">
                  <div
                    className="item"
                    onClick={() => handleEditRow(index, dataField)}
                  >
                    Edit
                  </div>
                  <div
                    className="item"
                    onClick={() => removeRow(index, dataField)}
                  >
                    Delete
                  </div>
                </div>
              </SummaryRow>
            );
          })}
        </div>
      );

    case "submit":
      return (
        <div
          style={{
            gridColumn: "1/-1",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              style={{ width: "10rem" }}
              variant="contained"
              onClick={submitHandler}
            >
              Submit
            </Button>
          )}
        </div>
      );

    case "searchAutocomplete":
      return (
        <Autocomplete
          style={{ width: "100%" }}
          disablePortal
          id="combo-box-demo"
          options={
            GlobalStore.getState()["startup"]?.[dataField.category][
              dataField.subCategory
            ][dataField.fieldName]?.options || []
          }
          disableClearable
          value={value}
          onChange={(event, newValue) => {
            handleChange(
              dataField.fieldName,
              newValue,
              dataField.category,
              dataField.subCategory
            );
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue != "") {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
                new: true,
              });
            }
            return filtered;
          }}
          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            if (option.name) {
              return option.name;
            }
            return option.title;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={options?.title}
              onChange={(e) =>
                handleSearch(
                  dataField.fieldName,
                  e.target.value,
                  dataField.category,
                  dataField.subCategory
                )
              }
              size="small"
            />
          )}
        />
      );

    case "multipleText":
      return (
        <div style={{ gridColumn: "1/-1" }}>
          <FormLabel>{options.title}</FormLabel>
          <div style={{ display: "flex", gap: "3rem", marginTop: "0.5rem" }}>
            {options.options.map((option, i) => {
              return (
                <TextField
                  key={i}
                  label={option.title}
                  id={option.id}
                  size="small"
                  onChange={(e) =>
                    handleChange(
                      option.id,
                      e.target.value,
                      dataField.category,
                      dataField.subCategory
                    )
                  }
                  value={value[option.id]}
                  helperText={!(!!value[option.id]) ? helperText : ""}
                  error={!(!!value[option.id])}
                  style={
                    isActive
                      ? options.gridColumn
                        ? { gridColumn: options.gridColumn }
                        : null
                      : { display: "none" }
                  }
                />
              );
            })}
          </div>
        </div>
      );

    case "chipInput":
      return (
        <MuiChipsInput
          value={value}
          onChange={(value) =>
            handleChange(
              dataField.fieldName,
              value,
              dataField.category,
              dataField.subCategory
            )
          }
          size={"small"}
          label={options.title}
          error={error}
          helperText={helperText}
        />
      );
  }
};

const BuildImageViewer = ({ image, i, removeFileFromArray, dataField }) => {
  return (
    <div className="image-column">
      <div className="image" key={i}>
        <div
          className="close"
          onClick={() => removeFileFromArray(i, dataField)}
        >
          {Icons.closeRound}
        </div>
        {image.type == "video" ? (
          <div className="video">Video</div>
        ) : image.url ? (
          <img src={image.url} alt={i} />
        ) : (
          <img src={Gif.hourglass} alt={i} />
        )}
      </div>

      <div className="name">{image.error ? image.error : image.name}</div>
    </div>
  );
};

const BuildFileUploader = ({ options, dataField, handleFile, file, error, helperText, removeFile }) => {
  const hasUrl = !!file?.url;
  return (
    <FileComponent>
      <FormLabel>{options.title}</FormLabel>
      <Button
        component="label"
        variant="contained"
        style={{ width: "100%" }}
        disableRipple={hasUrl}
        disabled={file?.loading}
      >
        {!hasUrl ? (
          <>
            {Icons.uploadFile}&nbsp;No File Chosen
            <input
              type={"file"}
              id={dataField.fieldName}
              accept="application/pdf"
              onChange={(e) => handleFile(e, dataField)}
              hidden
            />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>{file?.file?.name}</span>
            <span
              onClick={(e) => {
                removeFile(dataField);
                e.preventDefault();
              }}
              className="delete"
            >
              {Icons.delete}
            </span>
          </div>
        )}
      </Button>
      <FormHelperText style={error ? { color: "var(--errorTextColor)" } : null}>
        &nbsp;&nbsp;&nbsp;&nbsp;{helperText ? helperText : <>&nbsp;</>}
      </FormHelperText>
    </FileComponent>
  );
};

const SummaryRow = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  margin-bottom: 1rem;
  gap: 1rem;

  .content {
    border-radius: var(--borderRadius);
    padding: 1rem;
    border: 1px solid gray;
    max-height: 10rem;
    overflow: auto;
    width: 100%;

    .row {
      margin-bottom: 1rem;
      width: 100%;

      .heading {
        color: var(--text1);
        font-size: 1rem;
      }

      .desc {
        font-size: 0.9rem;
        margin-top: 0.2rem;
        word-break: break-all;
      }
    }
  }

  .action {
    display: flex;
    gap: 1rem;
    cursor: pointer;
  }
`;

const FileComponent = styled.div`
  .delete {
    display: flex;
    align-items: center;
    transition: 0.3s color;

    &:hover {
      color: red;
    }
  }
`;