import {
  OutletContainer,
  SchemeTabContainer,
  SchemeTopPanel,
} from "../../../../utils/customStyledComponents";
import PageNavigation from "../../../customComponents/PageNavigation";
import paths from "../../../../utils/links";
import SchemeViewToggle from "../../customComponents/SchemeViewToggle";
import { useEffect, useState } from "react";
import CustomSchemeTabs from "../../customComponents/CustomSchemeTab";
import CustomTabView, {
  applicationSideRole,
} from "../../customComponents/CustomTabView";
import { Alert, CircularProgress } from "@mui/material";
import {
  setSidebarActive,
  handleTopBarTitle,
} from "../../../../utils/helperHandler";
import { checkApplication, getSchemeDetails } from "../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../providers/GlobalProvider";
import { BigProvider } from "../../../../providers/scheme/big/BigProvider";
import { useParams } from "react-router-dom";

const pathsForPageNavigation = [
  {
    text: "Schemes",
    block: true,
    link: "",
  },
  {
    text: "BIG",
    block: true,
    link: paths.big.link,
  },
  {
    text: "Call",
    block: true,
    link: paths.big.link,
  },
];

const BIG = ({ from }) => {
  const [pathsArr, setPathArr] = useState([...pathsForPageNavigation]);

  //get big scheme id from url
  const { id } = useParams();

  const [activeView, setActiveView] = useState(0);
  const [loading, setLoading] = useState(true);
  const [scheme, setScheme] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const { user } = globalSelector((state) => state.auth);

  const [isFounder, setFounder] = useState(false);
  const [application, setApplication] = useState(null); //for founder side

  const dispatch = globalDispatch();
  const controller = new AbortController();

  useEffect(() => {
    handleTopBarTitle(dispatch, paths.big);
    setSidebarActive(paths.big.id);
    getSchemeDetailsHandler();
  }, []);

  const getSchemeDetailsHandler = async () => {
    const response = await getSchemeDetails({schemeId: id}, dispatch, controller);
    
    if (response && response.status == 200) {
      setScheme(response.msg);
      let pathArr = [...pathsArr];
      pathArr[2] = {
        text: response.msg.schemeName,
        block: false,
        link: paths.big.link + `/${response.msg._id}`,
      }
      setPathArr(pathArr);
      
      handleTopBarTitle(dispatch, paths.big, paths.big.title + " - " + response.msg?.schemeName, paths.big.link + `/${response.msg._id}`);

      await getApplication();
    } else if (response) {
      alert(response.msg);
    } else {
      alert("Something went wrong! Try again");
    }
  }

  //this function wont run when visited by manager view
  const getApplication = async () => {
    //this is to check whether the founder or pm as landed on this page and if founder then load only their application

    if (!applicationSideRole.includes(user.role.roleId)) {
      setLoading(false);
      return;
    }
    setFounder(true);
    try {
      const response = await checkApplication(
        { scheme: id, check: true },
        dispatch,
        controller
      );
      if (response && response.status == 200) {
        setApplication(response.msg);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <OutletContainer>
      <BigProvider>
        <PageNavigation paths={pathsArr} active={scheme?.schemeName} />

        <SchemeTopPanel>
          <div className="leftPanel">
            <div className="title">About</div>
            <div className="content">
              {scheme?.description}
            </div>
          </div>

          {!loading && user ? (
            isFounder ? null : (
              <div className="rightPanel">
                <SchemeViewToggle
                  active={activeView}
                  setActive={setActiveView}
                />
              </div>
            )
          ) : null}
        </SchemeTopPanel>

        {!loading && user ? (
          <SchemeTabContainer>
            {isFounder && !application ? (
              <div className="no-application">No Application found!</div>
            ) : (
              <>
                <CustomSchemeTabs
                  active={activeTab}
                  setActive={setActiveTab}
                  isFounder={isFounder}
                  from={from}
                />

                <CustomTabView
                  active={activeTab}
                  application={application}
                  from={from}
                  scheme={scheme}
                />
              </>
            )}
          </SchemeTabContainer>
        ) : (
          <div className="loader">
            {!user ? (
              <Alert color="error">Unauthorized!</Alert>
            ) : (
              <CircularProgress />
            )}
          </div>
        )}
      </BigProvider>
    </OutletContainer>
  );
};

export default BIG;
