import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  checkApplication,
  getAppicationData,
} from "../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../providers/GlobalProvider";
import ApplicantApplicationSide from "../ikp/big/phases/applicant/Application";
import Applications from "../ikp/big/phases/ikp/Applications";
import CustomSchemeTabs from "./CustomSchemeTab";

export const applicanteeSideRole = ["super_admin", "program_manager", "program_associate"];
export const applicationSideRole = ["founder"];

const CustomTabView = ({
  active,
  application,
  setNotificationForTab,
  from,
  scheme,
}) => {
  const { user } = globalSelector((state) => state.auth);
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const [applicationData, setApplicationData] = useState(null);

  //states if viewed by ikp side
  const [activeTab, setActiveTab] = useState(0);

  const getApplicationDataHandler = async () => {
    try {
      const response = await getAppicationData(
        { applicationId: application._id },
        dispatch,
        controller
      );
      if (response && response.status == 200) {
        setApplicationData(response.msg);
      }
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    getApplicationDataHandler();
  }, []);

  const Tab = ({ active }) => {
    //ikp side
    if (applicanteeSideRole.includes(user.role.roleId)) {
      if (from == "manager") {
        switch (active) {
          case 0:
            return (
              <ApplicantApplicationSide
                application={application}
                applicationData={applicationData?.phase1}
                applicationDataId={applicationData?._id}
                from={from}
                getApplicationDataHandler={getApplicationDataHandler}
              />
            );
        }
      }
      switch (active) {
        case 0:
          return <Applications setNotificationForTab={setNotificationForTab} scheme={scheme} />;
      }
    } else if (applicationSideRole.includes(user.role.roleId)) {
      switch (active) {
        case 0:
          return (
            <ApplicantApplicationSide
              application={application}
              applicationData={applicationData?.phase1}
              applicationDataId={applicationData?._id}
              getApplicationDataHandler={getApplicationDataHandler}
            />
          );
      }
    }
  };

  return (
    <Container>
      {from == "manager" ? (
        <CustomSchemeTabs
          active={activeTab}
          setActive={setActiveTab}
          isFounder={true}
          from={from}
          afterApplicationSelect={true}
        />
      ) : (
        <></>
      )}
      {<Tab active={from == "manager" ? activeTab : active} />}
    </Container>
  );
};

export default CustomTabView;

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 1rem 0;
`;
