import { Dialog } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../customComponents/CustomButton";
import CustomTab from "../../customComponents/CustomTab";
import { ListTab, OutletContainer, TabContainer } from "../../../utils/customStyledComponents";
import { globalDispatch } from "../../../providers/GlobalProvider";

const usersTabs = {
    0: "Joined Users",
    // 1: "Blocked Users",
  };

const OtherUsers = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [displayForm, setDisplayForm] = useState(false);
  const navigate = useNavigate();
  const dispatch = globalDispatch();

  const changeActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const handleDisplayForm = () => {
    setDisplayForm(!displayForm);
  };

  const DisplayTab = ({ currentTab }) => {
    switch (currentTab) {
      
    }
  };
  return (
    <OutletContainer>
      <ListTab>
        <CustomTab
          startupTabs={usersTabs}
          activeTab={activeTab}
          changeActiveTab={changeActiveTab}
        />
        <CustomButton title="Add User" onClickHandler={handleDisplayForm} />
      </ListTab>

      <TabContainer>
        <DisplayTab currentTab={activeTab} />
      </TabContainer>

      <Dialog
      open={displayForm}
      onClose={handleDisplayForm}
      >

      </Dialog>
    </OutletContainer>
  );
};

export default OtherUsers;
