import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import styled from "styled-components";
import { Icons } from "../../../../utils/mediaExports";

const BuildFormField = ({
  field,
  options,
  value,
  handleChange,
  handleFiles,
  removeFile,
  handleCheckbox,
  fileLoading,
  from,
  index,
  status,
}) => {
  const isReadOnly = from == "manager";
  switch (options.inputType) {
    case "text":
      return (
        <>
          <FormLabel className={`label ${options.mt ? "mt1" : null}`}>
            {options.label}
            <div className="statusContainer">
              {index == 0 ? (
                <span
                  className={`status ${status == "approved" ? "approved" : ""}`}
                >
                  {status == "approved" ? "Ok" : status}
                </span>
              ) : (
                <></>
              )}
            </div>
          </FormLabel>
          <TextField
            size="small"
            className="input"
            value={value}
            multiline
            rows={3}
            disabled={isReadOnly}
            onChange={(e) => handleChange(field, options.id, e.target.value)}
          />
        </>
      );

    case "file":
      return (
        <>
          <FormLabel className={`label ${options.mt ? "mt1" : null}`}>
            {options.label}
            <div className="statusContainer">
              {index == 0 ? <span
                  className={`status ${status == "approved" ? "approved" : ""}`}
                >
                  {status == "approved" ? "Ok" : status}
                </span> : <></>}
            </div>
          </FormLabel>
          <div className="fileUploader">
            {from == "manager" ? (
              <></>
            ) : (
              <FileUploader
                classes="component"
                handleChange={(file) => handleFiles(field, options.id, file)}
                name="file"
                types={["PDF"]}
                multiple={true}
                children={
                  <FileArea>
                    <div className="message">
                      <div>Drag and Drop</div>
                      <div>Or</div>
                      <div>Browse for files</div>
                      <CircularProgress
                        style={{
                          display: fileLoading[options["id"]]
                            ? "block"
                            : "none",
                        }}
                        size={20}
                      />
                    </div>
                  </FileArea>
                }
              />
            )}
            <div className="files">
              {value.map((file, index) => {
                return (
                  <div className="file mt1" key={index}>
                    <Chip
                      label={<a href={file.url} target={"_blank"}>{file.name}</a>}
                      onDelete={() => {
                        removeFile(field, options.id, index);
                      }}
                      deleteIcon={from == "manager" ? <></> : Icons.delete}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      );

    case "checkbox":
      return (
        <>
          <FormLabel className={`label ${options.mt ? "mt1" : null}`}>
            {options.label}
            <div className="statusContainer">
              {index == 0 ? <span
                  className={`status ${status == "approved" ? "approved" : ""}`}
                >
                  {status == "approved" ? "Ok" : status}
                </span> : <></>}
            </div>
          </FormLabel>
          <FormGroup className="formgroup">
            {options.options.map((option, index) => (
              <>
                {option.textbox ? (
                  <TextField
                    size="small"
                    disabled={isReadOnly}
                    onChange={(e) =>
                      handleCheckbox(
                        field,
                        options.id,
                        option.id,
                        e.target.value,
                        true
                      )
                    }
                    value={option.value}
                    label="Others"
                  />
                ) : (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        disabled={isReadOnly}
                        value={option.value}
                        onChange={(e) =>
                          handleCheckbox(
                            field,
                            options.id,
                            e.target.value,
                            e.target.checked
                          )
                        }
                        checked={!!value[option.value]}
                      />
                    }
                    label={option.title}
                  />
                )}
              </>
            ))}
          </FormGroup>
        </>
      );
  }
};

export default BuildFormField;

export const FileArea = styled.div`
  .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9rem;
    line-height: 1.1rem;
    color: var(--text3);
  }
`;
