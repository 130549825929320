import {
  Alert,
  Autocomplete,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  assignAssociateForApplication,
  changeApplicationStatus,
  getSchemeAssociates,
} from "../../../../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../../../../providers/GlobalProvider";
import { DialogBody } from "../../../../../../../utils/customStyledComponents";
import { Icons } from "../../../../../../../utils/mediaExports";
import CustomButton from "../../../../../../customComponents/CustomButton";

const ActionDialog = ({
  handleActionDialog,
  activeAction,
  application,
  getApplicationDataHandlerForManager,
}) => {
  const [loading, setLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);

  const [associate, setAssociate] = useState(null);
  const [associates, setAssociates] = useState([]);

  const dispatch = globalDispatch();
  const controller = new AbortController();

  const { id, applicationId } = useParams();

  useEffect(() => {
    handleAssociateSearch();
  }, []);

  const handleAssociateSearch = async () => {
    try {
      const response = await getSchemeAssociates(
        { schemeId: id },
        dispatch,
        controller
      );
      if (response && response.status == 200) {
        setAssociates(response.msg);
      } else {
        setAssociates([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelect = (e, value) => {
    setAssociate(value._id);
  };

  const submitHandler = async () => {
    try {
      setServerResponse(null);
      setLoading(true);
      const response = await assignAssociateForApplication(
        {
          schemeId: id,
          associate: application?.reviewer ? application?.reviewer : associate,
          applicationId: applicationId,
          status: application?.reviewer ? "revoke" : "assign",
        },
        dispatch,
        controller
      );

      if (response && response.status == 200) {
        if (application?.reviewer) {
          setAssociate(null);
        }
        setServerResponse({ type: "success", body: response.msg });
        getApplicationDataHandlerForManager();
      } else {
        setServerResponse({
          type: "error",
          body: response?.msg || "Something went wrong!",
        });
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const changeApplicationStatusHandler = async (status) => {
    setServerResponse(null);
    const response = await changeApplicationStatus(
      { status: status, applicationId: applicationId, schemeId: id },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      setServerResponse({ type: "success", body: response.msg });
      getApplicationDataHandlerForManager();
    } else {
      setServerResponse({
        type: "error",
        body: response?.msg || "Something went wrong!",
      });
    }
  };

  switch (activeAction) {
    case "assign":
      return (
        <DialogBody>
          <div className="closeButton" onClick={handleActionDialog}>
            {Icons.close}
          </div>

          <DialogTitle className="dialogTitle">
            {application?.reviewer ? "Revoke Reviewer" : "Assign Reviewer"}
          </DialogTitle>
          <DialogContent className="content">
            {application?.reviewer ? (
              <div className="">{application?.reviewer?.name}</div>
            ) : (
              <Autocomplete
                freeSolo
                disableClearable
                options={associates}
                getOptionLabel={(option) => option.name}
                onChange={handleSelect}
                renderInput={(params) => (
                  <TextField
                    className="firstField"
                    {...params}
                    // onChange={handleAssociateSearch}
                    label="Search Associates"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            )}

            <div className="actionField">
              <CustomButton title={"Submit"} onClickHandler={submitHandler} />
            </div>

            {serverResponse ? (
              <Alert color={serverResponse?.type || "error"}>
                {serverResponse.body}
              </Alert>
            ) : (
              <></>
            )}
          </DialogContent>
        </DialogBody>
      );

    case "nextRound":
      return (
        <DialogBody>
          <div className="closeButton" onClick={handleActionDialog}>
            {Icons.close}
          </div>

          <DialogTitle className="dialogTitle">
            Move Application to next Round
          </DialogTitle>
          <DialogContent className="content">
            <div className="actionField">
              <CustomButton
                title={"Confirm"}
                onClickHandler={() =>
                  changeApplicationStatusHandler("applicationPassed")
                }
              />
            </div>

            {serverResponse ? (
              <Alert color={serverResponse?.type || "error"}>
                {serverResponse.body}
              </Alert>
            ) : (
              <></>
            )}
          </DialogContent>
        </DialogBody>
      );

    case "reject":
      return (
        <DialogBody>
          <div className="closeButton" onClick={handleActionDialog}>
            {Icons.close}
          </div>

          <DialogTitle className="dialogTitle">Reject Application</DialogTitle>
          <DialogContent className="content">
            <div className="actionField">
              <CustomButton
                title={"Confirm"}
                onClickHandler={() =>
                  changeApplicationStatusHandler("applicationRejected")
                }
              />
            </div>
            {serverResponse ? (
              <Alert color={serverResponse?.type || "error"}>
                {serverResponse.body}
              </Alert>
            ) : (
              <></>
            )}
          </DialogContent>
        </DialogBody>
      );
  }
};

export default ActionDialog;
