import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { fetchApplications } from "../../../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../../../providers/GlobalProvider";
import { BigDispatch } from "../../../../../../providers/scheme/big/BigProvider";
import { updateBigNotification1 } from "../../../../../../reducers/schemes/big/applicantee/tabs";
import { ApplicationStatus } from "../../../../../../utils/constants";
import { Gif } from "../../../../../../utils/mediaExports";
import CustomButton from "../../../../../customComponents/CustomButton";
import CustomTextSearch from "../../../../../customComponents/CustomSearchText";
import NewApplicantModal from "../../../../customComponents/modals/NewApplicantModal";

const Applications = ({scheme}) => {
  const dispatch = globalDispatch();
  const bigDispatch = BigDispatch();
  const controller = new AbortController();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [textSearch, setTextSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [applications, setApplications] = useState([]);

  const {id} = useParams();

  const modalHandler = (e, reason) => {
    if (reason == "backdropClick") return;
    setOpen(false);
  };

  const getApplications = async () => {
    const response = await fetchApplications(
      { scheme: id },
      dispatch,
      controller
    );

    if (response && response.status == 200) {
      // console.log(response.msg);
      bigDispatch(
        updateBigNotification1({
          id: "applications",
          value: response.msg?.length || 0,
        })
      );
      setApplications(response.msg);
    }
    setLoading(false);
  };

  useEffect(() => {
    getApplications();
  }, []);

  return (
    <SchemePhaseTabContainer>
      <div className="topRow">
        <CustomTextSearch
          value={textSearch}
          setValue={(value) => setTextSearch(value)}
          upcomingBanner = {true}
        />
        <CustomButton
          title="Add New Applicant"
          onClickHandler={() => setOpen(true)}
        />
      </div>

      <TableContainer className="tableContainer pm_applicationsTable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width={"40px"} align="center">Sl No</TableCell>
              <TableCell>Company Name</TableCell>
              {/* <TableCell>Scheme</TableCell> */}
              <TableCell>Industry</TableCell>
              <TableCell>Documents</TableCell>
              <TableCell>Date of Application</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="loading">
                    <img src={Gif.flying} alt="loading" />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              applications.map((application, index) => {
                return (
                  <TableRow
                    key={index}
                    className="applicationRow"
                    onClick={() => navigate(`/schemes/big/${id}/${application._id}`)}
                  >
                    <TableCell align="center">{index+1}</TableCell>
                    <TableCell>{application.companyName}</TableCell>
                    {/* <TableCell>{application.scheme}</TableCell> */}
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell><span className="statusText">{ApplicationStatus[application?.status] || application?.status}</span></TableCell>
                  </TableRow>
                );
              })
            )}

            {!loading && applications.length == 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="no-application">No Applications!</div>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={modalHandler}
        PaperProps={{ style: { width: "30rem" } }}
      >
        <NewApplicantModal
          modalHandler={modalHandler}
          refreshApplications={getApplications}
          scheme={scheme}
        />
      </Dialog>
    </SchemePhaseTabContainer>
  );
};

export default Applications;

export const SchemePhaseTabContainer = styled.div`
  .statusTextApplication {
    font-size: 1.1rem;
    text-transform: capitalize;
    padding: 0.5rem 1rem;
    width: max-content;
    border-radius: 8px;
  }

  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .no-application {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 1.2rem;
    color: var(--errorTextColor);
  }

  .container {
    .actions {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 2rem;
      justify-content: flex-end;

      .title {
        color: var(--text1);
      }
    }
  }

  .tableContainer {
    background: #fff;
    margin-top: 2rem;

    .loading {
      display: flex;
      justify-content: center;

      img {
        width: 10rem;
      }
    }

    table {
      thead {
        tr {
          th {
            color: var(--text1);
            font-size: 1rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .pm_applicationsTable {
    .statusText {
      text-transform: capitalize;
    }

    .applicationRow {
      cursor: pointer;

      &:hover {
        background: rgba(1, 210, 178, 0.2);
      }
    }
  }

  .applicantTable {
    .statusText {
      text-transform: capitalize;
    }

    table {
      thead {
        tr {
          th {
            color: var(--text3);
            font-size: 1.1rem;
            font-weight: 400;
          }
        }
      }

      tbody {
        tr {
          td {
            color: var(--text1);
          }
        }
      }
    }

    .subtextAction {
      color: var(--text3);
      cursor: pointer;
    }

    .text {
      font-size: 1.1rem;
    }

    .actionText {
      font-size: 1.1rem;
      color: var(--primary);
      cursor: pointer;
      transition: 0.1s;

      &:hover {
        color: green;
      }
    }
  }
`;
